/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { likePost, unLikePost } from "../../store/asyncMethods/PostMethods";
import { useDispatch, useSelector } from "react-redux";
import gtag from "ga-gtag";
import { path } from "../../path";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Improve from "./dislike.svg";
import AlertInvalidFormat from "../Alert/AlertInvalidFormat";
import { WebSocketContext } from "../../WebSocket";
import axios from "axios";

const Appriciate = ({
  improveLikeCount,
  post,
  update,
  setUpdate,
  improveUpdate,
  setImproveUpdate,
  appriciateLikeCount,
  setImprovelikeCount,
  setAppriciateLikeCount,
}) => {
  const dispatch = useDispatch();
  const toastOption = {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 500,
    pauseOnHover: true,
    draggable: true,
    theme: "light",
  };
  const [EmptyFieldAlert, setEmptyFieldAlert] = useState("");
  useEffect(() => {
    if (post?.improve && post?.improve?.includes(user._id)) {
      setImproveUpdate(true);
    }
  }, []);
  const socket = useContext(WebSocketContext);
  const { user, token } = useSelector((state) => state.AuthReducer);

  const getNotification = async () => {
    const config = {
      host: `${path}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `${path}`,
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.get(
        `${path}/getNotification/${user._id}`,
        config
      );
      const filteredNoti = response.data.data.filter(
        (noti) =>
          noti.fromUserId !== null &&
          noti.type !== "Not Improve" &&
          noti.type !== "Unappreciate" &&
          noti.postId !== null
      );
      dispatch({ type: "UPDATE_NOTIFICATION", payload: filteredNoti });
    } catch (error) {
      console.log(error);
    }
  };

  const handleImprove = (e) => {
    e.preventDefault();
    if (improveUpdate) {
      //IF POST IS ALREADY IMPROVED
      setImproveUpdate(false); //UNIMPROVE THE POST IF ALREADY IMPROVED
      dispatch(unLikePost(post._id, "Not Improve"));
      setImprovelikeCount((prev) => prev - 1); //DECREASE THE IMPROVE COUNT
      setImproveUpdate(false);
      var impData = {
        post: post,
        user: user,
        type: "not improve",
      };
      socket.emit("react notification", impData);
    } else {
      if (update) {
        //IF POST IS APPRICIATED BEFORE THEN
        setUpdate(false); //UNAPPRICIATE THE POST
        dispatch(unLikePost(post._id, "unappreciate"));
        setAppriciateLikeCount(appriciateLikeCount - 1); //DECREASE THE APPRICIATE COUNT
        setImproveUpdate(true); //IMPROVES THE POST
        setImprovelikeCount((prev) => prev + 1); //INCREASE THE IMRPOVE COUNT
        dispatch(likePost(post._id, "improve"));
        var impData = {
          post: post,
          user: user,
          type: "improve",
        };
        socket.emit("react notification", impData);
        var aprData = {
          post: post,
          user: user,
          type: "unappreciate",
        };
        socket.emit("react notification", aprData);
      } else {
        //IF POST IS NEITHER APPRICIATED NOR IMPROVED
        setImproveUpdate(true); //IMRPOVE THE POST
        dispatch(likePost(post._id, "improve"));
        setImprovelikeCount((prev) => prev + 1); //INCERASE THE IMRPOVE COUNT
        setUpdate(false);
        setImproveUpdate(true);
        // console.log(post);
        var impData = {
          post: post,
          user: user,
          type: "improve",
        };
        socket.emit("react notification", impData);
      }
    }
    getNotification();
  };
  useEffect(() => {
    const handler = (event) => {
      if (event.target.classList.contains("improve-event")) {
        gtag("event", "improve", {});
      }
    };
    document.addEventListener("click", handler);
    return () => {
      document.removeEventListener("click", handler);
    };
  });
  return (
    <>
      <div className="w-full  hover:cursor-pointer" onClick={handleImprove}>
        {EmptyFieldAlert.length > 0 && (
          <AlertInvalidFormat
            msg={EmptyFieldAlert}
            toggle={setEmptyFieldAlert}
          />
        )}
        <div className="flex flex-row items-center justify-start transition duration-200 border border-viewMore rounded-3xl hover:bg-neutral w-full">
          <button className="improve-event p-1 rounded-3xl  flex items-center">
            <div className="improve-event  border-viewMore rounded-3xl p-2 bg-[#F6F5FA]">
              <img src={Improve} alt="" height={"20px"} width={"20px"} />
            </div>
            {!improveUpdate ? (
              <div className="improve-event flex flex-col justify-evenly mx-2 mr-4 text-slate-400">
                <p className="improve-event text-[0.75rem]">Improve</p>
                <p className="improve-event text-[0.75rem]  text-left">
                  {improveLikeCount}
                </p>
              </div>
            ) : (
              <div className="improve-event flex flex-col justify-evenly mx-2 mr-4 text-yellow-400">
                <p className="improve-event font-gilroy text-[0.75rem] text-left">
                  Improve
                </p>

                {/* <p >
                  {improveLikeCount}
                </p> */}
                <p className="improve-event text-[.75rem] text-left">
                  {improveUpdate && improveLikeCount > 1
                    ? "You and " + "+" + (improveLikeCount - 1)
                    : ""}
                </p>
                <p className="improve-event text-[.75rem] text-left">
                  {improveUpdate && improveLikeCount == 1 ? " You" : ""}
                </p>
              </div>
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default Appriciate;
