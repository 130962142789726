import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

const MarketingAdminRoutes = (props) => {
  const { user } = useSelector((state) => state.AuthReducer);
  return (
    <div>
      {user.isMarketingAdmin ? (
        <Route
          path={props.path}
          exact={props.exact}
          component={props.component}
        />
      ) : (
        <Redirect to="/" from="/skills" />
      )}
    </div>
  );
};


MarketingAdminRoutes.propTypes = {
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  component: PropTypes.elementType.isRequired,
};

export default MarketingAdminRoutes;
