import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { path } from "../../path";
import { useSelector } from "react-redux";
import { Avatar } from "@mui/material";
import VideoPreview from "./VideoPreviewPanel";
import pauseIcon from "./pause-icon.svg";
import EnterPasswordModal from "../Modals/EnterPasswordModal";

const SubmissionsList = () => {
  const { submissionId, adminAccess } = useParams();
  const [submission, setSubmission] = useState();
  const [selectedVideo, setSelectedVideo] = useState(null); // State to manage selected video
  const [name, setName] = useState(null); // State to manage selected video
  const { user, token } = useSelector((state) => state.AuthReducer);
  const [modal, setModal] = useState(false);
  const [accessPassword, setAccessPassword] = useState("");
  const [selectItem, setSelectItem] = useState(0);
  const [selectName, setSelectName] = useState("");
  const [errMsg, setErrMsg] = useState(null);

  if (errMsg) {
    console.log("Error nesg ", errMsg);
  }

  const getSubmission = async (submissionId) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios.get(
        `${path}/contest/getSubmissionsbyId/${submissionId}`,
        config
      );
      const rawData = response?.data?.result;
      setSubmission(rawData);
    } catch (error) {
      console.error("FETCH SUBMISSION ERROR", error.message);
    }
  };

  useEffect(() => {
    getSubmission(submissionId);
  }, [submissionId]);

  const info = submission?.map((item) => ({
    id: item._id,
    username: item.user ? item.user.username : "User Not found",
    firstName: item.user ? item.user.firstName : "Null",
    lastName: item.user ? item.user.lastName : "404",
    profilePicture: item.user ? item.user.profilePicture : null,
    mediaUrl: item.mediaUrl,
  }));

  const deleteFunction = async (id) => {
    await axios.post(`${path}/contest/submissionDelete`, {
      submissionId: id,
    });
  };

  function alertBox(id, name, access) {
    let adminAcc = access;
    // Ensure that `adminAcc` is a boolean
    if (typeof adminAcc === "string") {
      adminAcc = JSON.parse(adminAcc.toLowerCase());
    }
    if (adminAcc === false) {
      if (window.confirm("Are you sure you want to delete this item?")) {
        deleteFunction(id)
          .then(() => {
            setTimeout(() => {
              alert("Deleted successfully");
              window.location.reload();
            }, 200);
          })
          .catch((error) => {
            console.error("Error deleting item:", error);
            alert("Failed to delete the item. Please try again.");
          });
      }
    } else {
      setModal(true);
      setSelectItem(id);
      setSelectName(name);
    }
  }

  const handleAdminAccess = async (pass) => {
    try {
      const response = await axios.post(`${path}/checkAdminAccess`, {
        email: user.email,
        password: pass,
      });
      if (response.data.status === "success") {
        setModal(false);
        deleteFunction(selectItem).then(
          setTimeout(() => {
            alert("Deleted successfully");
            window.location.reload();
          }, 200)
        );
      }
      if (response.data.status === "failed") {
        setErrMsg(response.data.message);
        alert("Wrong password, enter again");
        setAccessPassword("");
      }
    } catch (error) {
      console.log("ERROR ", error);
    }
  };

  return (
    <div className="">
      {modal && (
        <EnterPasswordModal
          handleAdminAccess={handleAdminAccess}
          setAccessPassword={setAccessPassword}
          setModal={setModal}
          accessPassword={accessPassword}
          message={`delete ${selectName} submission`}
        />
      )}

      <div className="flex flex-1 justify-center md:hidden">
        {selectedVideo && (
          <VideoPreview
            video={selectedVideo}
            username={name}
            setVideo={setSelectedVideo}
          />
        )}
      </div>
      <div className="flex h-screen flex-1 bg-[#F6F5FA] ">
        <div className="flex flex-col flex-1  px-6 py-2 w-1/2 ">
          <div className=" items-center my-3 font-bold text-2xl ">
            <div className="flex mx-auto">
              <p className="mr-3">Submissions </p>
              {info?.length && <p>({info.length})</p>}
            </div>
          </div>

          {!info && (
            <div className="flex justify-center items-center">
              <p className="text-3xl text-red-800">No Submissions yet</p>
            </div>
          )}

          <div className="border   border-transparent  rounded-xl bg-[#FFFFFF] h-[38rem]  p-3 overflow-hidden overflow-y-auto">
            {info?.map((item) => (
              <>
                <div
                  key={item.id}
                  className={`flex justify-between px-3 space-x-1 xl:space-x-2 text-[#707C97] py-3 border rounded-lg  border-transparent border-b-gray-400`}>
                  <div className="flex space-x-4">
                    {/* <p className="bg-gray-400 text-black font-bold px-3 h-11 flex items-center border rounded-full">
              {i + 1}
            </p> */}
                    <div>
                      {item.profilePicture ? (
                        <img
                          className="h-11 w-11 border rounded-full"
                          src={item.profilePicture}
                          alt="Profile Image"
                        />
                      ) : (
                        <Avatar />
                      )}
                    </div>

                    <div className="flex flex-col px-3">
                      <p className="font-bold text-[15px] sm:text-sm">
                        {item.firstName} {item.lastName}
                      </p>
                      <p>{item.username} </p>
                    </div>
                  </div>

                  <div className="pr-10 block lg:flex space-x-0 lg:space-x-2 space-y-2 lg:space-y-0">
                    {item.mediaUrl === "..." ? (
                      <div>
                        <p className="text-center text-red-700">
                          No file found
                        </p>
                      </div>
                    ) : (
                      <button
                        className="border bg-[#F6F5FA] border-[#008080] transition duration-300 ease-in-out hover:scale-90 hover:cursor-pointer w-24 px-3 h-8 py-1 rounded-xl cursor-pointer flex items-center space-x-2"
                        onClick={() => {
                          setSelectedVideo(item.mediaUrl);
                          setName(item.firstName + " " + item.lastName);
                        }} // Set the selected video on click
                      >
                        <img src={pauseIcon} className=" h-3 lg:h-4 " alt="" />
                        <p className="text-[#008080] text-sm lg:text-sm">
                          View
                        </p>
                      </button>
                    )}
                    <button
                      className="ml-2 border border-[black] w-24 px-3 h-8 py-1 space-x-1 bg-[#F6F5FA] hover:bg-red-600 transition duration-300 ease-in-out hover:scale-110 hover:cursor-pointer rounded-xl cursor-pointer flex items-center text-red-700 hover:text-white"
                      onClick={() =>
                        alertBox(
                          item?.id,
                          item.firstName + " " + item.lastName,
                          adminAccess
                        )
                      }>
                      <img
                        src="https://cdn-icons-png.flaticon.com/512/3405/3405244.png"
                        className="h-5"
                        alt=""
                      />
                      <p className="  text-sm lg:text-[14px]">Delete</p>
                    </button>
                  </div>
                </div>
                {/* <div className=" w-full my-2 border rounded-xl border-[#D8D8D8] h-24 px-4">
                <div className="flex flex-col px-4 mr-8 my-2 space-y-2">
                  <input
                    type="text"
                    placeholder="Write a comment"
                    className="ml-3 border border-transparent"
                  />
                  <div className="flex justify-end">
                    <div className="flex w-1/3">
                      <input
                        type="text"
                        placeholder="Write marks out of 10 "
                        className="pl-2 border rounded-s-full text-[#707C97] bg-[#F6F5FA]"
                      />
                      <button className="border rounded-full bg-[#008080] text-white py-2 px-3 -ml-5">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div> */}
              </>
            ))}
          </div>
        </div>
        <div className="hidden md:contents">
          <VideoPreview
            video={selectedVideo}
            username={name}
            setVideo={setSelectedVideo}
          />
        </div>
      </div>
    </div>
  );
};

export default SubmissionsList;
