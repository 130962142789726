/* eslint-disable no-warning-comments */
/* eslint-disable react/no-array-index-key */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { path } from "../../../path";
import PhotoPost from "./Posts/PhotoPost";

function ReportedPosts() {
  const { token } = useSelector((state) => state.AuthReducer);
  const [loading, setLoading] = useState(false);
  const [reportedPosts, setReportedPosts] = useState([]);
  const [searchtext, setsearchtext] = useState("");
  const [Postfallback, setpostfallback] = useState([]);

  useEffect(() => {
    const getAllReportedPosts = async () => {
      try {
        const config = {
          host: `${path}`,
          headers: {
            "Access-Control-Allow-Origin": `${path}`,
            Authorization: `Bearer ${token}`,
          },
        };
        setLoading(true);
        const res = await axios.get(
          `${path}/admin/fetchallreportedposts`,
          config
        );
        setReportedPosts(res?.data);
        setpostfallback(res?.data);
        setLoading(false);
      } catch (error) {
        alert("Error in fetching reported posts.", error);
      }
    };

    getAllReportedPosts();
  }, []);

  console.log(reportedPosts);
  const searchPost = (e) => {
    if (e.trim() === "") {
      setReportedPosts(Postfallback);
    } else {
      const filterdPost = reportedPosts?.filter((post) => {
        return post?.postId?._id.includes(e);
      });
      console.log(filterdPost);
      setReportedPosts(filterdPost);
    }
  };

  useEffect(() => {
    searchPost(searchtext);
  }, [searchtext]);

  return (
    <div className="text-primary bg-page min-h-[100vh] px-2  py-[3rem] overflow-x-none ">
      <div>
        <input
          type="text"
          value={searchtext}
          onChange={(e) => setsearchtext(e.target.value)}
          placeholder="Search By Post id"
          className="text-grayish search-tab-event p-4 rounded-3xl border m-1 border-viewMore w-[31vw] h-8 mb-2 bg-primary outline-none
              focus:ring-2 ring-[#008080] Searchbox"
        />
      </div>
      <div className="flex flex-col items-center justify-between w-[80vw] px-2 overflow-y-auto">
        {loading ? (
          <p className="text-primary">Loading...</p>
        ) : reportedPosts.length > 0 ? (
          reportedPosts.map((rp, index) => {
            // if (rp?.postId?.type === "photo") {
            return <PhotoPost key={index} post={rp} />;
            // }
          })
        ) : (
          <p>No reported posts found.</p>
        )}
      </div>
    </div>
  );
}

export default ReportedPosts;
