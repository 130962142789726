import React from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
//import Button from '@mui/material/Button';

import appStoreBadge from "./img/appStoreBadge.svg";
import playStoreBadge from "./img/playStoreBadge.png";

const DeepLinkBanner = () => {
    const { id: contestId } = useParams();
    const { user } = useSelector((state) => state.AuthReducer);
    const isLoggedIn = localStorage.getItem('myToken') !== null;

    return (
        <main className="relative h-[100vh] text-center">
            <div className={`py-[30px] ${isLoggedIn && 'mt-[50px]'} px-5`}>
                <h1 className="text-[30px] font-semibold" style={{ color: user?.theme==="dark"? "white": "#333333" }}>Get the Feedants app:</h1>
            </div>
            <div className="flex flex-row max-[350px]:flex-col justify-center items-center gap-1">
                <a href="https://apps.apple.com/in/app/feedants/id6477408585" className="w-[175px] h-[57px]">
                    <img className="w-full h-full" src={appStoreBadge} />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.feedants&pcampaignid=web_share" className="w-[175px] h-[57px]" >
                    <img className="w-full h-full" src={playStoreBadge} />
                </a>
            </div>
            {/* <div className="my-[30px] px-5">
                <h1 className="text-[30px] font-semibold" style={{ color: user?.theme==="dark"? "white": "#333333" }}>Already have the app:</h1>
            </div> */}
            {/* <div className="flex justify-center max-w-[350px] mx-auto px-5">
                <Button
                  onClick={() => {
                    window.location.href = `feedants://competitionLink/${contestId}`;
                  }}
                  style={{ backgroundColor: '#008080', color: 'white', width: '100%' }}
                >
                    Open in App
                </Button>
            </div> */}
            <div className="my-[30px] px-5">
                <Link to={`/competition/${contestId}`} style={{ color: "#999" }}>or continue on Feedants website</Link>
            </div>
        </main>
    )
}

export default DeepLinkBanner;