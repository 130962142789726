import React, { useContext, useEffect, useState, useRef } from "react";
import { Avatar } from "@mui/material";
import { likePost, unLikePost } from "../../store/asyncMethods/PostMethods";
import { useDispatch, useSelector } from "react-redux";
import Appri from "./like.svg";
import AApri from "./like.svg";
// import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AlertInvalidFormat from "../Alert/AlertInvalidFormat";
import gtag from "ga-gtag";
import { WebSocketContext } from "../../WebSocket";

const Appriciate = ({
  appriciateLikeCount,
  post,
  update,
  setUpdate,
  improveUpdate,
  setImproveUpdate,
  setAppriciateLikeCount,
  setImprovelikeCount,
}) => {
  const { user } = useSelector((state) => state.AuthReducer);
  const dispatch = useDispatch();
  // const toastOption = {
  //   position: toast.POSITION.TOP_CENTER,
  //   autoClose: 500,
  //   pauseOnHover: true,
  //   draggable: true,
  //   theme: "light",
  // };

  const socket = useContext(WebSocketContext);
  const [EmptyFieldAlert, setEmptyFieldAlert] = useState("");

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      // Cleanup: Set isMounted to false when the component unmounts
      isMounted.current = false;
    };
  }, []);

  const handleClick = () => {
    if (isMounted.current) {
      gtag("event", "appreciate", {});
    }
  };

  useEffect(() => {
    if (post?.appreciate && post?.appreciate.includes(user._id)) {
      setUpdate(true);
    }
    const handler = (event) => {
      if (event.target.classList.contains("appreciate-event")) {
        gtag("event", "appreciate", {});
      }
    };
    document.addEventListener("click", handler);
    // console.log("i am rerendring ");

    return () => {
      document.removeEventListener("click", handler);
    };
  }, []);

  const handleAppreciate = async (e) => {
    try {
      e.preventDefault();
      console.log("handleAppreciate start");

      const { _id, userId } = post;

      if (update) {
        setUpdate(false);
        console.log("Unappreciating...");
        dispatch(unLikePost(_id, "unappreciate"));
        setAppriciateLikeCount((prev) => prev - 1);

        if (userId._id !== user._id) {
          const aprData = { post, user, type: "unappreciate" };
          socket.emit("react notification", aprData);
        }
      } else {
        if (improveUpdate) {
          setImproveUpdate(false);
          console.log("Unimproving...");
          dispatch(unLikePost(_id, "Not improve"));
          setImprovelikeCount((prev) => prev - 1);
        }

        console.log("Appreciating...");
        setUpdate(true);
        setAppriciateLikeCount((prev) => prev + 1);
        dispatch(likePost(_id, "appreciate"));

        if (userId._id !== user._id) {
          console.log("Notifying about appreciation...");
          const impData = { post, user, type: "not improve" };
          socket.emit("react notification", impData);

          const aprData = { post, user, type: "appreciate" };
          socket.emit("react notification", aprData);
        }
      }

      console.log("handleAppreciate end");
    } catch (error) {
      console.error("handleAppreciate error:", error);
    }
  };

  return (
    <>
      <div className="w-full hover:cursor-pointer" onClick={handleAppreciate}>
        {EmptyFieldAlert?.length > 0 && (
          <AlertInvalidFormat
            msg={EmptyFieldAlert}
            toggle={setEmptyFieldAlert}
          />
        )}
        <div className="flex flex-row items-center justify-start transition duration-200     border border-viewMore rounded-3xl  hover:bg-neutral w-full">
          <button
            className="p-1 rounded-3xl flex items-center appreciate-event"
            onClick={handleClick}>
            <div className="appreciate-event border-viewMore rounded-3xl p-2 bg-[#F6F5FA]">
              <Avatar
                className="appreciate-event "
                src={update ? Appri : AApri}
                sx={{ width: 20, height: 20 }}
              />
            </div>
            {update ? (
              <div className="appreciate-event flex flex-col mx-2 mr-4 text-teal-700">
                <p
                  className="appreciate-event font-gilroy text-[0.75rem]"
                  id="appreciate">
                  Appreciate
                </p>
                <p className="appreciate-event text-[0.75rem] text-left">
                  {update && appriciateLikeCount > 1
                    ? `You and +${appriciateLikeCount - 1}`
                    : ""}
                </p>
                <p className="appreciate-event text-[0.75rem] text-left">
                  {update && appriciateLikeCount === 1 ? "You" : ""}
                </p>
              </div>
            ) : (
              <div className="appreciate-event flex flex-col justify-evenly mx-2 mr-4 text-slate-400">
                <p className="appreciate-event text-[0.75rem] text-[]">
                  Appreciate
                </p>
                <p className="appreciate-event text-[0.75rem] text-left">
                  {appriciateLikeCount}
                </p>
              </div>
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default Appriciate;
