import axios from "axios";
import React, { useState,  useContext } from "react";
import { useSelector } from "react-redux";

import { path } from "../../path";
import "./ChangePassword.css";
import Alert from "../Alert/Alert";
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { WebSocketContext } from "../../WebSocket";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
import {PropTypes} from "prop-types"
const ChangePassword = ({ setVal, getNotification }) => {
  
  const { user, token } = useSelector((state) => state.AuthReducer);

  const socket = useContext(WebSocketContext);

  const [state, setState] = useState({
    curPassword: "",
    newPassword: "",
    confirmNewPassword: "",
    showPassword_1: false,
    showPassword_2: false,
    showPassword_3: false,
  });
   
  
  const [, setPasswordPolicy] = useState(false);

  
  const handleInput = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const handleTogglePassword_1 = (e) => {
    e.preventDefault();
    setState({
      ...state,
      showPassword_1: !state.showPassword_1,
    });
  };
  const handleTogglePassword_2 = (e) => {
    e.preventDefault();
    setState({
      ...state,
      showPassword_2: !state.showPassword_2,
    });
  };
  
  const toastOption = {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 3000,
    pauseOnHover: true,
    draggable: true,
    theme: "light",
  };
  const validPassword = new RegExp(
    "[a-zA-Z0-9!@#$%^&*()_+|<>?~].{6,15}$"
  );
  const [updateoption, setupdateoption] = useState(false);
  const updatePasswordForm = async (e) => {
    e.preventDefault();

    if (state.curPassword.trim().length === 0) {
      toast.error("Please enter your current password", toastOption);
      return;
    }

    if(state.newPassword.length < 7 || state.newPassword.length > 16){
      toast.error("Password must be 7-16 characters", toastOption);
      return;
    }

    if (!state.curPassword || !state.newPassword || !state.confirmNewPassword) {
      // alert("Please fill all the fields");
      toast.error("Please fill all the fields", toastOption);
      return;
    }

    if (state.newPassword !== state.confirmNewPassword) {
      // alert("New password and Confirm New Password must match");
      toast.error(
        "New password and confirm new password do not match.",
        toastOption
      );
      return;
    }

    if (!validPassword.test(state.curPassword)) {
      toast.error("Current Password is Invalid", toastOption);
      return;
    }

    if (state.curPassword === state.newPassword) {
      // alert("Current password and New Password must be different");
      toast.error(
        "Current password and New Password must be different",
        toastOption
      );
      return;
    }
    let response;
    const config = {
      headers: {
        "Access-Control-Allow-Origin": `${path}`,
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      response = await axios.post(
        `${path}/changePassword/${user._id}`,
        state,
        config
      );
      if (Object.keys(response.data).length === 1) {
        toast.error(response.data.msg, toastOption);
        setState({ ...state, newPassword: "", confirmNewPassword: "" });
      } else {
        socket.emit("profile update", user);
        setupdateoption(true);
        setTimeout(() => {
          setupdateoption(false);
          document.getElementById("home-content").style.opacity = 1;
        }, 4000);

        setState({
          curPassword: "",
          newPassword: "",
          confirmNewPassword: "",
        });
        setVal((prev) => !prev);
      }
      getNotification = { getNotification };
    } catch (error) {
      console.log(error.message);
      toast.error("Error in updating password", toastOption);
    }
  };

  return (
    <>
      <div>
        {!updateoption ? (
          <form onSubmit={updatePasswordForm}>
            <div className=" bg-neutral flex justify-between items-center mx-2 my-5 border border-viewMore rounded-lg md:w-[50%]">
              <input
                className="bg-neutral rounded-lg text-grayish w-full p-2  outline-none"
                type={state.showPassword_1 ? "text" : "password"}
                onChange={handleInput}
                value={state.curPassword}
                name="curPassword"
                placeholder="Enter Current Password"
              />
              <button
                className="text-teal-700 mr-2 cursor-pointer"
                onClick={handleTogglePassword_1}>
                {!state.showPassword_1 ? (
                  <VisibilityRoundedIcon />
                ) : (
                  <VisibilityOffRoundedIcon />
                )}
              </button>
            </div>
            <div className="bg-neutral flex justify-between items-center mx-2 my-5 border border-viewMore rounded-lg md:w-[50%]">
              <input
                className="bg-neutral rounded-lg text-grayish w-full p-2  outline-none"
                type={state.showPassword_2 ? "text" : "password"}
                onChange={handleInput}
                value={state.newPassword}
                onFocus={() => {
                  setPasswordPolicy(true);
                }}
                onBlur={() => {
                  setPasswordPolicy(false);
                }}
                name="newPassword"
                placeholder="Enter New Password"></input>
              <button
                className="text-teal-700 mr-2 cursor-pointer"
                onClick={handleTogglePassword_2}>
                {!state.showPassword_2 ? (
                  <VisibilityRoundedIcon />
                ) : (
                  <VisibilityOffRoundedIcon />
                )}
              </button>
              {/* {passwordPolicy == true && (
                <div className=" w-[95%] left-[3%] md:left-[30%] md:w-[27vw] absolute md:top-[12.5rem] top-[18rem] z-10">
                  <div className="flex flex-row items-center justify-center">
                    <ArrowDropUpIcon
                      fontSize="large"
                      className="text-teal-600 w-[100%] -mb-5"
                      style={{ height: 50, width: 50 }}
                    />
                  </div>
                  <div className=" bg-card  rounded-lg p-2 shadow-lg border-2 border-viewMore text-xs">
                    <ul className="text-teal-600">
                      <li>
                        1) Password must be minimum 7 & maximum 16 characters
                        long.
                      </li>
                      <li>
                        2) Password must contain atleast 1 uppercase and 1
                        lowercase letter.
                      </li>
                      <li>
                        3) Password must contain atleast 1 numeric and 1 special
                        character .
                      </li>
                    </ul>
                  </div>
                </div>
              )} */}
            </div>
            <div className="bg-neutral flex justify-between items-center mx-2 my-5 border border-viewMore rounded-lg md:w-[50%]">
              <input
                className="bg-neutral rounded-lg text-grayish w-full  p-2  outline-none"
                type={state.showPassword_3 ? "text" : "password"}
                onChange={handleInput}
                value={state.confirmNewPassword}
                name="confirmNewPassword"
                placeholder="Confirm New Password"></input>
              
            </div>
            <hr className="border-viewMore"></hr>
            <div className="flex flex-row justify-center md:justify-start">
              <input
                data-testid="update-button"
                style={{ border: "black", marginLeft: "0.5rem" }}
                type="submit"
                className="cursor-pointer rounded-full field4 w-[73vw] h-[5vh] px-2 border-2 border-[#0E0E0E] bg-[#0E0E0E]  my-[0.7rem] hover:[#4950F6] md:h-[6vh] md:text-[1rem] md:w-[15vw] md:border-[#0E0E0E] md:bg-[#0E0E0E] md:hover:bg-gray-700 md:hover:border-[#8C94AC] text-white transition duration-200"
                value="Update"
              />
            </div>
          </form>
        ) : (
          <div>
            <Alert
              msg={"Your Password has been updated successfully"}
              type="success"
            />
          </div>
        )}
      </div>
    </>
  );
};

ChangePassword.propTypes = {
  setVal: PropTypes.func.isRequired,
  getNotification: PropTypes.func.isRequired
}
export default ChangePassword;
