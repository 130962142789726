import React, { useState } from 'react';
import yellow from "./yellow.png";
import axios from 'axios';
import { path } from '../../path';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

function ResultCard({ result, setopenResult, contestId }) {
  const [res, setRes] = useState(result.map((r) => ({ ...r, editable: false })));

  const toggleEditable = (index) => {
    setRes(res.map((score, i) => i === index ? { ...score, editable: !score.editable } : score));
  };

  const handleScoreChange = (index, newValue) => {
    setRes(res.map((score, i) => i === index ? { ...score, score: newValue } : score));
  };

  const declareResult = async () => {

    const scores = res.map((r) => ({
      id: r.id,
      score: r.score ? parseFloat(r.score) : 0,
    }));
    try {
      const epsilon = 0.000001; // You can adjust the tolerance as needed
      if (
        Math.abs(scores[0].score - scores[1].score) < epsilon ||
        Math.abs(scores[1].score - scores[2].score) < epsilon
      ) {
        console.log(scores);
        toast.error("Top 3 submission cannot have the same score");
        return;
      }

    } catch (e) {
      console.log("Need 3 submissions atleast",e);
    }

    // Create an object with ranking
    const resultss = {};
    scores.forEach((submission, index) => {
      resultss[index + 1] = submission.id;
    });
    let results = []
    results = Object.keys(resultss).map(key => ({
      rank: parseInt(key),
      submissionId: resultss[key]
    }));

    console.log(results)
    // Send ranking to result API
    console.log(contestId)
    console.log(results)

    try {
      const res = await axios.post(`${path}/contest/resdeclared`, {
        scores,
        contestId,
        results,
      });

      console.log("Result declared successfully:", res.data);
      toast.success("Result Declared Succesfully");
      setopenResult(false);
    } catch (error) {
      console.error("Error declaring result:", error);
      toast.error("There is some error in declaring result")
    }
  };

  return (
    <div className="rounded-t-lg AllFriends bg-primary">
      <div className="AllFriendsContainer w-[80%] sm:w-[30%] border border-viewMore">
        <div className="top flex items-center justify-between rounded-t-[12px]">
          <p className="text-lg text-center font-gilroy-semibold">Result Preview</p>
          <button onClick={() => setopenResult(false)}>&#10005;</button>
        </div>
        {result?.length > 0 ? (
          <div className="allfriends flex flex-col py-2 gap-4" data-testid="allFriends-div">
            {res?.map((score, index) => (
              <div key={score.id} className="flex justify-between cursor-pointer flex-1 items-center gap-2 p-1.5 md:py-0.5 rounded-md max-h-[53px]">
                <div className='flex items-center justify-center gap-2'>
                  <img loading="lazy" src={score.profilePicture || yellow} alt="profilePic" className="shrink-0 aspect-square rounded-md w-[39px] h-[39px]" />
                  <div className="flex flex-col items-start justify-center">
                    <h2 className="flex-auto my-auto text-compSec font-gilroy-semibold text-xs">{`${score.firstName} ${score.lastName}`}</h2>
                  </div>
                </div>
                <div>
                  {score.editable ? (
                    <input type="numeric" className='border border-blue-600' value={score.score} onChange={(e) => handleScoreChange(index, e.target.value)} />
                  ) : (
                    <span>{score.score}</span>
                  )}
                  <button onClick={() => toggleEditable(index)}>✏️</button>
                </div>
              </div>
            ))}
            <button className='bg-teal-600 mt-6 rounded-xl text-white font-gilroy-semibold p-2 ml-1 hover:bg-teal-500 transition-opacity' onClick={() => {
              setRes(res.map(score => ({ ...score, editable: false })));
              declareResult();
            }}> Declare</button>
          </div>
        ) : (
          <p className="text-primary">Result not declared</p>
        )}
      </div>
    </div>
  );
}


ResultCard.propTypes = {
  result: PropTypes.array.isRequired,
  setopenResult: PropTypes.func.isRequired,
  contestId: PropTypes.string.isRequired,
};

export default ResultCard
