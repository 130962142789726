import Button from "@mui/material/Button";
import { Avatar } from "@mui/material";
// import React from "react";
import { useEffect, useState } from "react";
import { path } from "../../path.js";
import axios from "axios";
// import { useSelector } from "react-redux";
import CompDetails from "./CompDetails.jsx";
import CompDetails2 from "./CompDetails2.jsx";
import React from "react";
import CompCard from "./CompCard.js";
// import CompAll from "./CompAll.jsx"
import { Helmet } from "react-helmet";

import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";

export default function CompetetionDesktop({ progress, processing }) {
  const {
    user,
    // token
  } = useSelector((state) => state.AuthReducer);
  const [contestData, setContestData] = useState({});
  const [allcontest, setAllcontest] = useState([]);
  const [clickedcontest, setClickedContest] = useState([]);
  const [toggle2, setToggle2] = useState("teal-700");
  const [toggle, setToggle] = useState("primary");
  // const contestId = "";
  const { id } = useParams();

  const [toggles, setToggles] = useState(true);
  // const [reg,setReg] = useState("")
  const filterData = async (clicked) => {
    if (clicked === "Registered") {
      setClickedContest(
        allcontest && user
          ? allcontest.filter((contest) =>
              contest.participatedUser.some((p) => p === user._id)
            )
          : []
      );
      setToggle("primary");
      setToggle2("teal-700");
      setToggles(true);
    } else {
      setClickedContest(
        allcontest && user
          ? allcontest.filter(
              (contest) => !contest.participatedUser.some((p) => p === user._id)
            )
          : []
      );
      setToggle2("primary");
      setToggle("teal-700");
      setToggles(false);
    }
  };
  const getcontestdetails = async (cid) => {
    try {
      const res = await axios.get(`${path}/api/response/getContestId/${cid}`);
      console.log(res.data);
      setContestData(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };
  //   useEffect(()=>{
  // console.log(contestData)
  //   },[contestData])
  useEffect(() => {
    const getContests = async () => {
      try {
        const res = await axios.get(`${path}/api/response/getAllresponse`);
        console.log(res);
        console.log(res.data.data);
        setAllcontest(res?.data.data);
        // setContestData(res?.data.data[0])
      } catch (error) {
        console.log(error);
      }
    };
    getContests();
  }, []);

  useEffect(() => {
    const fetchcontestdetails = async () => {
      try {
        const res = await axios.get(`${path}/api/response/getContestId/${id}`);

        setContestData(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchcontestdetails();
  }, [id]);

  useEffect(() => {
    setClickedContest(
      allcontest && user
        ? allcontest.filter((contest) =>
            contest.participatedUser.some((p) => p === user._id)
          )
        : []
    );
    console.log(allcontest);
  }, [allcontest]);

  return (
    <div className="bg-compBgSec mx-auto  w-[80vw]">
      {/* Ignore the header code */}
      <Helmet>
        <title>Competitions</title>
        <meta
          name="description"
          content="Compete in the most challenging contests"
        />
      </Helmet>
      <header className="flex items-center justify-between p-4 border-b invisible">
        <div className="flex items-center space-x-4">
          <img alt="Black Skull Logo" className="h-8" src="/placeholder.svg" />
          <div className="relative">
            <input
              className="border rounded-full pl-10 pr-4 py-2"
              placeholder="Find frinds and more"
              type="text"
            />
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <Button className="bg-[#bd1e59] text-white">Competition</Button>
          <BellIcon className="text-gray-600" />
          <Avatar></Avatar>
        </div>
      </header>
      <main className="flex h-max pb-4">
        <section className="w-1/3 p-4 bg-compBgSec">
          {/* Trending competitions, currently it's listing all the competitions handles in CompCard component */}
          <h2 className="text-xl text-primary font-semibold mb-1 ml-5">
            Competitions
          </h2>
          <div className="mlg:flex flex-row p-3 py-0 hidden ">
            <div
              className={`text-${toggle2} text-xs whitespace-nowrap mx-2 ${toggles ? "border hover:cursor-pointer border-teal-700 rounded-full p-2 bg-primary" : "mt-2"}`}
              onClick={() => filterData("Registered")}>
              Registered Contests
            </div>
            <div
              className={`text-${toggle} text-xs whitespace-nowrap mx-2 ${!toggles ? "border hover:cursor-pointer border-teal-700 rounded-full p-2 bg-primary" : "mt-2"}`}
              onClick={() => filterData("Unregister")}>
              Unregistered Contests
            </div>
          </div>
          <div className="flex flex-row p-3 py-0 mlg:hidden">
            <div
              className={`text-${toggle2} text-xs mx-2 ${toggles ? "border hover:cursor-pointer border-teal-700 rounded-full p-2 bg-primary" : "mt-2"}`}
              onClick={() => filterData("Registered")}>
              Registered
            </div>
            <div
              className={`text-${toggle} text-xs mx-2 ${!toggles ? "border hover:cursor-pointer border-teal-700 rounded-full p-2 bg-primary" : "mt-2"}`}
              onClick={() => filterData("Unregister")}>
              Unregistered
            </div>
          </div>
          <div
            className="h-[70vh] mt-1 ml-2 bg-compBgSec text-primary overflow-y-scroll "
            style={{ scrollbarWidth: "none" }}>
            {clickedcontest.length > 0 &&
              clickedcontest?.map((contest) => (
                <main className="p-1 hover:cursor-pointer" key={true}>
                  <CompCard
                    contest={contest}
                    getcontestdetails={getcontestdetails}></CompCard>
                </main>
              ))}
          </div>
        </section>
        <section className="w-3/4 flex p-0   ">
          <section className="mlg:w-2/3 w-4/5   bg-primary">
            {/* <Card className=""> */}
            <div
              className="  mb-2 bg-primary text-primary"
              style={{ scrollbarWidth: "none" }}>
              {contestData && contestData.responses && (
                <CompDetails
                  contestData={contestData}
                  progress={progress}
                  processing={processing}></CompDetails>
              )}
            </div>
            {/* </Card> */}
          </section>
          <section
            className="mlg:w-1/3 w-2/5 p-4 h-full bg-primary "
            style={{ scrollbarWidth: "none" }}>
            <div className="bg-primary text-primary ">
              <CompDetails2 contestData={contestData}></CompDetails2>
            </div>
          </section>
        </section>
      </main>
    </div>
  );
}

function BellIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round">
      <path d="M6 8a6 6 0 0 1 12 0c0 7 3 9 3 9H3s3-2 3-9" />
      <path d="M10.3 21a1.94 1.94 0 0 0 3.4 0" />
    </svg>
  );
}
