import * as React from "react";
import feedants_logo from "../yellow.png"
import { Link } from "react-router-dom/cjs/react-router-dom";
import PropTypes from 'prop-types'; 
export default function G({ fields }) {
    return (
        <div className="flex flex-col text-primary lg:p-1 xl:p-3 gap-2 md:h-auto">
            <h1 className="font-gilroy-medium text-compPrimary text-base">{fields[0].title}</h1>
            <div className="grid grid-cols-2 md:flex flex-col gap-2 p-1 md:h-auto ">
                {fields[0].response.map((judge) => (
                    <Link key={judge?._id} to={`/userDetails/${judge?._id}`}>
                        <div className="flex flex-1 gap-2 items-center p-1.5 md:py-0.5 rounded-md bg-compBgSec md:bg-primary">
                            {(judge?.profilePicture !== "" && judge?.profilePicture) ?
                                <img
                                    loading="lazy"
                                    src={judge?.profilePicture}
                                    alt="profilePic"
                                    className="shrink-0 aspect-square rounded-md w-[29px] h-[29px]"
                                /> :
                                <div className="w-[29px] h-[29px]">
                                    <img
                                        loading="lazy"
                                        src={feedants_logo}
                                        alt="profilePic"
                                        className="shrink-0 aspect-square rounded-md w-[full] h-[full]"
                                    />
                                </div>
                            }
                            <h2 className="flex-auto my-auto text-compSec font-gilroy-semibold  text-xs">{judge.firstName} {judge.lastName}</h2>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
}

G.propTypes = {
    fields: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            response: PropTypes.arrayOf(
                PropTypes.shape({
                    _id: PropTypes.string.isRequired,
                    profilePicture: PropTypes.string,
                    firstName: PropTypes.string.isRequired,
                    lastName: PropTypes.string.isRequired,
                })
            ).isRequired,
        })
    ).isRequired,
};