import React,{ useEffect, useState } from "react";
import ContestCard from "./ContestCard";
import axios from "axios";
import { path } from "../../path";
import { useSelector } from "react-redux";
import MobileFooter from "../footer/MobileFooter";

function ContestList() {

    const [contest, Setcontest] = useState(null);
    const [clickedcontest, setClickedContest] = useState([])
    const { user } = useSelector((state) => state.AuthReducer);
    const [toggle, setToggle] = useState("teal-700")
    const [toggle2, setToggle2] = useState("primary")
    const [userRegister, setUserRegister] = useState(false)

    const filterData = async (clicked) => {
        if (clicked === "Registered") {

            setClickedContest(contest && user ? contest.filter((contest) => contest.participatedUser.some((p) => p === user._id)) : [])
            setToggle("primary");
            setToggle2("teal-700")
            setUserRegister(true);
        } else {
            setClickedContest(contest && user ? contest.filter((contest) => !contest.participatedUser.some((p) => p === user._id)) : [])
            setToggle2("primary");
            setToggle("teal-700")
            setUserRegister(false);
        }

    }

    useEffect(() => {
        const getallRes = async () => {
            const res = await axios.get(`${path}/api/response/getAllresponse`);
            // console.log(res)
            Setcontest(res?.data?.data)
        }
        getallRes()

    }, [])

    useEffect(() => {
        setClickedContest(contest && user ? contest.filter((contest) => !contest.participatedUser.some((p) => p === user._id)) : [])
    }, [contest, user])


    return (
        <>
            {contest !== null && <div className={`w-full ${toggle === "primary" ? "h-full" : "h-full"} bg-page`}>
                <div className="flex flex-col pb-11 mx-auto w-full max-w-[480px]">
                    <header className="flex gap-5 px-5">
                        <div className="flex mt-11 flex-col">
                            <h1 className="text-base font-gilroy-semibold text-compSec">Competitions</h1>
                            <div className={` text-xs pt-2 font-gilroy-medium text-${toggle2} cursor-pointer`} onClick={() => filterData("Registered")}>
                                Registered Contests
                            </div>
                        </div>
                        <div className={`flex-auto pt-2 self-end  text-xs font-gilroy-medium text-${toggle} cursor-pointer`} onClick={() => filterData("Unregister")}>
                            Unregistered Contests
                        </div>
                    </header>
                    <main className="p-4">
                        {clickedcontest.length > 0 && clickedcontest.map((contest) => (
                            <ContestCard
                                key={contest._id}
                                contest={contest.responses}
                                contestId={contest._id}
                                userRegister={userRegister}
                            />
                        ))}
                    </main>
                </div>
                <MobileFooter />
            </div>}

        </>
    );
}

export default ContestList;