/* eslint-disable */

import { Avatar } from "@mui/material";
import axios from "axios";
import React from "react";
import { path } from "../../../../path";
import {useState} from "react";

function PhotoPost({ post }) {
  const { postId } = post;
  const [dele,setDele] =useState(false)

  const deleteReportPostHandler = async (reportedPostId) => {
    try {
      const res = await axios.delete(
        `${path}/admin/deletereportedpost/${reportedPostId}`
      );
      if(res.data.success){
        alert("Deleted successfully")
        setDele(true);return;
      }
      else{alert("Error in deleting post")
    return}
    } catch (error) {
      alert("Error in deleting post.");
    }
  };
  const openVideo = () => {
    window.open(post.postId.video, '_blank');
  };

  return (
    <div
      className={`border-2 m-2 p-2 rounded-lg ${dele ? `hidden` : ""} w-[50vw]`}
      style={{ borderColor: post.report.length > 5 ? "red" : "gray" }} >
      <div className="flex justify-between">
        <div className="flex space-x-2 items-center">
          {postId?.userId?.profilePicture ? (
            <Avatar src={postId?.userId?.profilePicture} />
          ) : (
            <Avatar>{postId?.userName.slice(0, 1).toUpperCase()}</Avatar>
           
          )}
          <div>
            <h2 className="font-semibold">{postId?.userName}</h2>
            <p className="text-[0.5rem]">{postId?.createdAt.slice(0, 10)}</p>
            <h2 className="font-semibold">{postId?.userName}</h2>
            <p className="text-[0.5rem]">{postId?.createdAt.slice(0, 10)}</p>
          </div>
        </div>
        <button
          className="text-red-500"
          onClick={() => deleteReportPostHandler(post._id)}>
          Delete
        </button>
      </div>
      <div className="flex space-x-4 my-2">
      <div className="h-16 flex justify-center">
      {post?.postId?.image[0] && (
        <img src={post.postId.image[0]} className="h-16" alt="Post Image" />
      )}

      {post?.postId?.body && (
        <button onClick={() => alert(post.postId.body)}>
          Show Content
        </button>
      )}
      {post?.postId?.video && (
        <video controls className="h-16 w-16">
        <source src={post.postId.video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      )}
      </div>
      <table className="border-collapse border w-full">
  <thead>
    <tr className="border-b">
      <th className="border-r px-4 py-2">S.No</th>
      <th className="border-r px-4 py-2">Username</th>
      <th className="border-r px-4 py-2">Report Type</th>
      <th className="px-4 py-2">Description</th>
      <th className="px-4 py-2">Caption</th>
    </tr>
  </thead>
  <tbody>
    {post?.userId.map((p, index) => (
      <tr key={index} className="border-b">
        <td className="border-r px-4 py-2 text-[0.8rem] text-center">
          {index + 1}
        </td>
        <td className="border-r px-4 py-2 text-[0.8rem] text-center">{p}</td>
        <td className="border-r px-4 py-2 text-[0.8rem] text-center">
          {post.report[index]}
        </td>
        <td className="px-4 py-2 text-[0.8rem] text-center">
          {post.description[index]}
        </td>
        <td className="ml-2 px-4 py-2 text-[0.8rem] ">
          {post?.postId?.imageCaption != null
            ? post?.postId?.imageCaption
            : post?.postId?.videoCaption}
        </td>
      </tr>
    ))}
  </tbody>
</table>

      </div>
    </div>
  );
}

export default PhotoPost;
