import React from "react";
import SubCard from "./SubCard";
import { useState, useEffect } from "react";
import MobileFooter from '../footer/MobileFooter';
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { path } from "../../path";
import ResultCard from "./ResultCard";
import useIsCurrentDateTimeGreater from "../../useIsCurrentDateTimeGreater";
import { Helmet } from "react-helmet";


const Judges = () => {
  // collect the contestid through params as the Id is needed to run through an api which populates submission
  const { contestId } = useParams();
  const { user, token } = useSelector((state) => state.AuthReducer);
  const [submissions, setSubmissions] = useState();
  const [media, setMedia] = useState();
  const [marks, setMarks] = useState();
  const [username,setUsername] = useState();
  const [openResult, setopenResult] = useState(false);
  const history = useHistory();
  const allowed = useIsCurrentDateTimeGreater("Submission end",contestId)

  // Entry criteria

  useEffect(() => {
    if (allowed===false || !user.judge.includes(contestId)) {
      history.goBack();
    }
    console.log(allowed)
  }, [allowed,user])


  const getmedia = (mediaUrl,username) => {
    setMedia(mediaUrl);
    setIsOverlayVisible(!isOverlayVisible);
    setUsername(username);
  };


  // Get the updated submission and pass it down
  const previewresult =  () => {
    const config = {
      host: `${path}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `${path}`,
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      axios.get(`${path}/contest/getSubmissionsbyId/${contestId}`, config)
      .then((response) => {
        const filteredSubmissions = response.data.result.filter((f) => f.paid === true);
        setSubmissions(filteredSubmissions);
        return filteredSubmissions;
      })
      .then((filteredSubmissions) => {
        if (filteredSubmissions.length > 0) {
          // Convert finalScore to integer and handle empty fields
          const scores = filteredSubmissions.map((submission) => ({
            id: submission._id,
            score: submission.finalScore ? parseFloat(submission.finalScore) : 0,
            firstName: submission.user.firstName,
            lastName: submission.user.lastName,
            profilePicture: submission.user.profilePicture
          }));
    
          // Sort scores by score
          scores.sort((a, b) => b.score - a.score);
    
          if (scores.length >= 3) {
            setMarks(scores);
            console.log(marks);
          } else {
            console.log("Need 3 submissions at least");
            toast.error("Score 3 submissions at least");
            return;
          }
        }
        setopenResult(true);
      })
      .catch((err) => {
        console.log(err.message);
        toast.error("Nretwork error")
      });
    
      
    } catch (err) {
      console.log(err.message);
    }
  }

  // useEffect(() => { console.log(marks); }, [marks])
  useEffect(() => {
    async function getAllSubmissions() {
      const config = {
        host: `${path}`,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": `${path}`,
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const response = await axios.get(
          `${path}/contest/getSubmissionsbyId/${contestId}`,
          config
        );
        console.log(response);
        const paidsub = response.data.result.filter((f)=> f.paid===true)
        setSubmissions(paidsub);
        // setMedia(response.data.result[0].mediaUrl);
        console.log(submissions);
      } catch (err) {
        console.log(err.message);
      }
    }
    getAllSubmissions();
  }, [contestId]);
  useEffect(() => {
    console.log(submissions);
  }, [submissions]);
  useEffect(() => {
    console.log(media);
  }, [media]);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);

 

  const handleToggleOverlay = () => {
    setIsOverlayVisible(!isOverlayVisible);
  };

 
  return (
    <div className=" relative flex flex-row md:items-center w-[100%] md:pl-[10%] items-start justify-center h-screen bg-compBgSec">
        <Helmet>
          <title>Judging panel</title>
          <meta name="description" content="Scoring system" />
        </Helmet>
      {submissions?.length>0  ?
      <>
      
      <section
        className="md:w-[40%] w-[94%] h-[80%] md:ml-5 mt-11 md:mt-0 overflow-y-scroll md:pt-6 "
        style={{ scrollbarWidth: "none" }}>
        <h1 className="md:hidden mb-1">CrickWar Submissions</h1>
        <center className="z-[100]">
          {submissions?.map((s) => (
            <SubCard key={s._id} s={s} getmedia={getmedia} contestId={contestId}></SubCard>
          ))}
        </center>
        {/* Map s.mediaUrl */}
      </section>
      <div className="absolute z-[10] md:top-[91%] top-[87%] left-[40%] md:left-[22%] p-2 bg-teal-500 font-gilroy-semibold rounded-xl text-white">
        <button className="" onClick={() => {
          previewresult();
          
        }}>Preview result</button>
        {/* </div> */}
        {openResult && marks?.length >= 3 && <ResultCard result={marks} setopenResult={setopenResult} contestId={contestId} />}
      </div>
      {submissions?.length>0 && 
      
      <section className="w-[30%] mx-9 h-[75%] hidden md:flex mt-4 rounded-2xl bg-primary  flex-col items-start pt-10 justify-center ">
        {/* <div className="w-[60%] h-[90%] mt-5.5 rounded-2xl"> */}
        <video
          className="w-[66%] h-[90%] rounded-2xl aspect-auto object-cover"
          controls src={media}>

        </video>
        <p>{username}</p>


      </section>
      }
      {/* Top Overlay rounded-t-lg*/}
      {isOverlayVisible && (
        <div className="fixed top-0 left-0 w-full h-[30vh] bg-black opacity-30 z-10 md:hidden" onClick={handleToggleOverlay}></div>
      )}

      <div className={`fixed bottom-0 left-0 w-full h-[70vh] bg-white  transition-transform duration-300 rounded-lg ${isOverlayVisible ? 'translate-y-0' : 'translate-y-full'} z-10 md:hidden`}>
        {
          isOverlayVisible && (
            <div className="absolute top-[-50px] left-1/2 transform -translate-x-1/2 cursor-pointer" onClick={handleToggleOverlay}>
              <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_321_640)">
                  <path d="M27.7666 9.90318L20.7766 18.2336L12.4461 11.2436C11.6793 10.6001 10.5361 10.7002 9.89268 11.4669C9.24926 12.2337 9.34928 13.377 10.1161 14.0204L18.4465 21.0105L11.4564 29.3409C10.813 30.1077 10.913 31.2509 11.6798 31.8943C12.4466 32.5377 13.5898 32.4377 14.2333 31.6709L21.2233 23.3405L29.5538 30.3306C30.3206 30.974 31.4638 30.874 32.1072 30.1072C32.7506 29.3404 32.6506 28.1972 31.8838 27.5537L23.5534 20.5637L30.5435 12.2332C31.1869 11.4664 31.0868 10.3232 30.3201 9.67978C29.5533 9.03637 28.41 9.13639 27.7666 9.90318Z" fill="white" />
                </g>
                <defs>
                  <clipPath id="clip0_321_640">
                    <rect width="28.999" height="28.999" fill="white" transform="translate(0.572571 22.5742) rotate(-50)" />
                  </clipPath>
                </defs>
              </svg>
            </div>)
        }
        {/* <div className="w-[80%] h-[90%] bg-gray-300 mt-10 mx-auto rounded-2xl"></div> */}
        <div className="relative w-[70%] h-[87%] mt-10 mx-auto rounded-2xl overflow-hidden">
          <video className="w-full h-full object-cover" src={media} id='video' controls={true} loop></video>
        </div>

        <div className="left-1/2 transform  flex  justify-center items-center m-2 space-x-4" onClick={() => document.getElementById('video').play()}>
          <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.111 5.97897L5.75238 1.31631C5.25573 0.952629 4.66807 0.73358 4.05455 0.683441C3.44103 0.633301 2.82561 0.754029 2.2765 1.03224C1.72739 1.31045 1.26605 1.73529 0.943606 2.25964C0.621162 2.784 0.450214 3.38741 0.449711 4.00297V13.333C0.448753 13.9491 0.618807 14.5535 0.940944 15.0787C1.26308 15.604 1.72466 16.0296 2.27431 16.308C2.82395 16.5865 3.4401 16.707 4.05416 16.6561C4.66822 16.6052 5.25611 16.3849 5.75238 16.0196L12.111 11.357C12.533 11.0473 12.876 10.6427 13.1125 10.1758C13.349 9.70897 13.4722 9.19298 13.4722 8.66964C13.4722 8.1463 13.349 7.6303 13.1125 7.16343C12.876 6.69657 12.533 6.29194 12.111 5.98231V5.97897Z" fill="black" />
          </svg>
        </div>

      </div>

      <MobileFooter />
      </>:
      <p>No verified submissions yet</p>
    }
    </div>
  );
};
export default Judges;
