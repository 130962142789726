/* eslint-disable no-warning-comments */
/* eslint-disable react/no-array-index-key */

import React from "react";
import { useState, useEffect } from "react";
import { path } from "../../path";
import axios from "axios";
import feedants_logo from "../competition/yellow.png";

import { Link, useHistory } from "react-router-dom";

// import Avatar_similar_user from "../SimillarUsers/Avatar_similar_user.js";
import "./skills.css";
import UserAvatar from "./UserAvatar";
import AllFriends from "../AllFriendsPopUp.js/AllFriends";
import { useSelector } from "react-redux";
import { Skeleton } from "@mui/material";

const SimilarUser = () => {
  const { user, token } = useSelector((state) => state.AuthReducer);
  const [similarSkillsUsers, setSimilarSkillsUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [counter, setCounter] = useState(5);
  const [AllUser, setAllUser] = useState(false);
 
  const history = useHistory();

  //finding all the users having similar skillset
  const config = {
    host: `${path}`,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `${path}`,
      Authorization: `Bearer ${token}`,
    },
  };
  useEffect(() => {
    const findSimilarSkillsetUsers = async () => {
      setLoading(true);
      const res = await axios.get(
        `${path}/allSimilarSkillsUsers/${user._id}`,
        config
      );
      setSimilarSkillsUsers(res.data.data);
      setLoading(false);
      console.log(res.data.data);
    };
    findSimilarSkillsetUsers();
  }, []);

  // console.log(user);
  //   set the default color of the profile picture
 


  const frnd = user?.friends;

  const MutualFriends = (BioUser) => {
    // let LoginFriends = user.friends.map((fr) => {
    //   return fr._id;
    // });
    // let MutualFriends = BioUser.friends.filter((fr) => {
    //   return LoginFriends.includes(fr);
    // });

    // if (MutualFriends > 0) {
    //   return (
    //     <>
    //       {" "}
    //       <PeopleOutlineOutlinedIcon
    //         sx={{ color: "#7A7A7A", fontSize: "15px" }}
    //       />{" "}
    //       `${MutualFriends.length} Mutual Friends`
    //     </>
    //   );
    // }
    // // setfriends(MutualFriends.length)
    // return MutualFriends.length > 0
    //   ? `${MutualFriends.length} Mutual Interests`
    //   : "";
    if (BioUser === null) {
      return "";
    }
    let MutualFriends = frnd.filter((x) => BioUser.friends.includes(x));

    return MutualFriends.length > 0 && MutualFriends.length < frnd.length
      ? `${MutualFriends.length} Mutual Peers `
      : MutualFriends.length === 0 && `No Mutual Peers`;
  };

  return (
    <div
      style={{
        overflow: "auto",
        "@media (min-width: 767px)": {
          maxHeight: "71vh", // Adjust this accordingly for smaller devices
        },
      }}
      className={" w-[100%] h-max-[65vh] "}>
      <div className="hidden md:block container2 md:w-[100%] lg:w-[100%] similarUser h-[100%] overflow-auto">
        <div className="flex flex-col justify-center px-3 py-2 ml-0 border rounded-lg bg-feedNeutral text-primary border-viewMore h-[100%]">
          <div
            style={{ maxHeight: "9vh" }}
            className="h-max-[10vh] px-1 font-semibold text-primary lg:text-lg">
            Wirings
          </div>
          <hr className="border-viewMore" />
          {loading ? (
            <div className="w-full h-[45vh] flex flex-col gap-2 mt-2">
              <Skeleton
                sx={{
                  bgcolor: `${
                    user.theme === "dark" ? "rgba(255, 255, 255, 0.2)" : ""
                  }`,
                }}
                variant="rounded"
                width={"100%"}
                height={"18%"}></Skeleton>
              <Skeleton
                sx={{
                  bgcolor: `${
                    user.theme === "dark" ? "rgba(255, 255, 255, 0.2)" : ""
                  }`,
                }}
                variant="rounded"
                width={"100%"}
                height={"18%"}></Skeleton>
              <Skeleton
                sx={{
                  bgcolor: `${
                    user.theme === "dark" ? "rgba(255, 255, 255, 0.2)" : ""
                  }`,
                }}
                variant="rounded"
                width={"100%"}
                height={"18%"}></Skeleton>
              <Skeleton
                sx={{
                  bgcolor: `${
                    user.theme === "dark" ? "rgba(255, 255, 255, 0.2)" : ""
                  }`,
                }}
                variant="rounded"
                width={"100%"}
                height={"18%"}></Skeleton>
              <Skeleton
                sx={{
                  bgcolor: `${
                    user.theme === "dark" ? "rgba(255, 255, 255, 0.2)" : ""
                  }`,
                }}
                variant="rounded"
                width={"100%"}
                height={"18%"}></Skeleton>
            </div>
          ) : (
            // <p className="p-2 text-primary">Loading...</p>
            <div
              style={{ maxHeight: "45vh", overflow: "auto" }}
              className="scrollbar-leftsec h-max-[45vh] overflow-auto">
              <div className="flex flex-col gap-2 mt-2">
                {similarSkillsUsers.length > 1 ? (
                  similarSkillsUsers
                    .slice(0, counter)
                    .filter((s) => s._id !== user._id)
                    .map((x, index) => {
                      return (
                        // <div className=" h-12 hover:bg-neutral">
                        //   {" "}
                        //   <li className="px-2 py-[1px] rounded-xl  mt-1">
                        //     <Link
                        //       to={`userDetails/${fr._id}`}
                        //       className="flex items-center">
                        //       <div className=" z-[0] flex justify-center items-center">
                        //         <UserAvatar
                        //           path="wiring"

                        //           data={fr}></UserAvatar>
                        //       </div>
                        //       <div className="mx-3">
                        //         {MutualFriends(fr) === "" ? (
                        //           <div className="h-8 mt-3 text-sm">
                        //             <h2 className="font-semibold text-primary">
                        //               {fr.username}
                        //             </h2>
                        //           </div>
                        //         ) : (
                        //           <div className="h-4">
                        //             <h2 className="font-semibold ">
                        //               {fr.firstName + " " + fr.lastName}
                        //             </h2>
                        //           </div>
                        //         )}
                        //         <h3 className="text-[8px] pt-1">
                        //           {MutualFriends(fr)}
                        //         </h3>
                        //       </div>
                        //     </Link>
                        //   </li>
                        //   <hr className="mt-[0px] mb-[0px] border-viewMore" />
                        // </div>
                        <div
                          key={index}
                          onClick={() => {
                            {
                              if (x?._id === user._id) {
                                history.push("/profile");
                              } else {
                                history.push(`/userDetails/${x._id}`);
                              }
                              // users.close(false);
                            }
                          }}
                          className="flex cursor-pointer flex-1 items-center gap-2 p-1.5 md:py-0.5 rounded-md  max-h-[53px]">
                          {x?.profilePicture !== "" && x?.profilePicture ? (
                            <img
                              loading="lazy"
                              src={x?.profilePicture}
                              alt="profilePic"
                              className="shrink-0 aspect-square rounded-md w-[39px] h-[39px]"
                            />
                          ) : (
                            <img
                              loading="lazy"
                              src={feedants_logo}
                              alt="profilePic"
                              className="shrink-0 aspect-square ml-1 rounded-md w-[29px] h-[29px]"
                            />
                          )}

                          <div className="flex flex-col items-start justify-center">
                            <h2 className="flex-auto my-auto text-compSec font-gilroy-semibold text-xs">
                              {" "}
                              {x.firstName} {x.lastName}{" "}
                            </h2>
                            {MutualFriends(x) === null ? (
                              ""
                            ) : (
                              <h3 className="text-[0.6rem] mt-0 text-[#8D8D8D] mx-0">
                                {MutualFriends(x)}
                              </h3>
                            )}
                          </div>
                        </div>
                      );
                    })
                ) : (
                  <h2 className="w-56 px-1 py-2 color-white">
                    No similar user found.
                  </h2>
                )}
                {/* <hr className="border-viewMore" /> */}
              </div>
            </div>
          )}
          <div className="flex justify-center my-auto mt-2 ">
            {similarSkillsUsers.length > 5 &&
              similarSkillsUsers.length >= counter &&
              counter < 9 && (
                <button
                  className="p-2 px-3 transition duration-200 ease-in-out shadow-sm rounded-3xl bg-primary text-primary border-viewMore border-1 hover:bg-hover"
                  //onClick={handleExplore}
                  style={{ maxHeight: "9vh" }}
                  onClick={() => setAllUser(true)}>
                  <h2 className="text-primary">Explore More</h2>
                </button>
              )}
            {similarSkillsUsers.length > 10 && counter >= 9 && (
              <div className="flex">
                <button
                  className="p-2 px-3 w-[6rem]  rounded-3xl text-viewMore bg-primary transition duration-200 border-viewMore border-1 m-[5px] hover:bg-hover shadow-sm"
                  onClick={() => setAllUser(true)}>
                  <h2 className="text-primary">Show All </h2>
                </button>
                <button
                  className="p-2 px-3 w-[6rem]  rounded-3xl text-viewMore bg-primary transition duration-200 border-viewMore border-1 m-[5px] hover:bg-hover shadow-sm"
                  onClick={() => {
                    setCounter((curr) => {
                      return curr - 4;
                    });
                  }}>
                  <h2 className="text-primary">Show Less</h2>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      {AllUser ? (
        <AllFriends
          close={setAllUser}
          user={similarSkillsUsers}
          type={"wirings"}></AllFriends>
      ) : (
        ""
      )}
      <div>
        {/* <div
        className="ml-5 text-slate-300"
        data-testid="home">
        <div>
          {" "}
          <Link to={"/privacy-policy"} target={"_blank"}>
            Privacy policy |
          </Link>{" "}
          FeedAnts 2023
        </div>
      </div> */}
      </div>

      {/* <div className="absolute z-[-1] bg-page h-full w-full"></div> */}
      <div
        className={
          " py-24 md:hidden " + (counter > 5 ? "h-full " : "h-[100vh]")
        }>
        {/* Mobile  view */}
        <div className="h-full bg-page flex flex-col justify-center items-center md:hidden">
          <div className="border border-viewMore bg-card w-[95%] p-4 rounded-lg md:hidden">
            <h1 className="border-b-2 text-primary border-viewMore font-semibold pb-4 text-md md:hidden">
              Wirings
            </h1>
            <div className="md:hidden">
              {loading ? (
                <p className="p-2 text-primary">Loading...</p>
              ) : (
                <div className="md:hidden">
                  <ul>
                    
                    {similarSkillsUsers.length > 1 ? (
                      similarSkillsUsers
                        .slice(0, counter)
                        .filter((s) => s._id !== user._id)
                        .map((fr) => {
                          return (
                            <div className="flex flex-col" key={true}>
                              {" "}
                              <li className="flex items-center p-2 rounded-xl">
                                <Link
                                  to={`userDetails/${fr._id}`}
                                  className="flex items-center">
                                  <div className="flex items-center justify-center ">
                                    <UserAvatar
                                      path="wiring"
                                      sx={{
                                        width: 18,
                                        height: 18,
                                      }}
                                      data={fr}></UserAvatar>
                                  </div>
                                  <div className="mx-3">
                                    {MutualFriends(fr) === "" ? (
                                      <div className="h-8 mt-3">
                                        <h2 className="font-semibold text-primary ">
                                          {fr.username}
                                        </h2>
                                      </div>
                                    ) : (
                                      <div className="h-4">
                                        <h2 className="font-semibold text-primary ">
                                          {fr.firstName + " " + fr.lastName}
                                        </h2>
                                      </div>
                                    )}
                                    <h3 className="text-[8px] pt-1">
                                      {MutualFriends(fr)}
                                    </h3>
                                  </div>
                                </Link>
                              </li>
                              <hr className="border-viewMore" />
                            </div>
                          );
                        })
                    ) : (
                      <h2 className="w-56 px-1 py-2 color-white">
                        No similar user found.
                      </h2>
                    )}
                    <hr className="border-viewMore" />
                  </ul>
                </div>
              )}

              <div className="flex justify-center mx-auto my-auto mt-2 shadow-sm ">
                <button
                  className="p-2 px-3 rounded-3xl bg-neutral m-[5px]"
                  onClick={() => {
                    counter > 5
                      ? setCounter(5)
                      : setCounter(similarSkillsUsers.length);
                  }}>
                  <h2 className="text-primary">
                    {counter > 5 ? "Show less" : "Show All"}
                  </h2>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SimilarUser);
