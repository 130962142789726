import axios from "axios";
import {
  // UPDATE_ALTERNATE_EMAIL,
  SET_LOADER,
  LOGIN_ERRORS,
  CLOSE_LOADER,
  SET_MESSAGE,
  REGISTER_ERRORS,
  SET_USER,
  SET_FOLLOW,
  UPDATE_ACCOUNT,
  UPDATE_EMAIL,
  SET_CONTEST_SUBMISSION_UPLOADING
} from "../types/AuthTypes";
import { REDIRECT_TRUE, REPORTED_POSTS } from "../types/postTypes";
import { path } from "./../../path";
import { UpdateThemeAction } from "./ProfileMethods";
// import { useCookies } from "react-cookie";

// let headers = new Headers();

// headers.append('Content-Type', 'application/json');
// headers.append('Accept', 'application/json');
// headers.append('Origin','http://blaccsckull.com');

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {return parts.pop().split(';').shift();}
}





export const setContribution = (eventId) => {
  return {
    type: "CONTRIBUTION",
    payload: eventId,
  };
};
export const setUploadingUser = (isLoading) => {
  return {
    type: "SET_UPLOADING_USER",
    payload: isLoading,
  };
};

export const unsetContribution = (eventId) => {
  return {
    type: "UNSET_CONTRIBUTION",
    payload: eventId,
  };
};


export const setSubmissionUploading = (isUploading) => {
  return {
    type: SET_CONTEST_SUBMISSION_UPLOADING,
    payload: isUploading,
  };
};


export const getOtp = (type, email) => {
  return async (dispatch) => {
    const config = {
      host: `${path}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `${path}`,
      },
    };
    dispatch({ type: SET_LOADER });
    try {
      let response;
      if (type === "email") {
        response = await axios.post(`${path}/sendOtp`, { type, email }, config);
      } else {
        response = await axios.post(
          `${path}/sendOtp`,
          { type, phone: email },
          config
        );
      }
      //console.log("REDUCER: " + response.data.otp);
      dispatch({ type: "OTP_RECIEVED", payload: response.data.otp });
      dispatch({ type: "SET_TYPE", payload: type });
      dispatch({ type: "SET_OTPHASH", payload: response.data.oid });
      dispatch({ type: CLOSE_LOADER });
    } catch (error) {throw new Error(error);}
  };
};
export const getOtpAlternate = (type, email, userId) => {
  //console.log("get oto-p alternate")
  return async (dispatch) => {
    const config = {
      host: `${path}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `${path}`,
      },
    };
    dispatch({ type: SET_LOADER });
    try {
      let response;
      if (type === "email") {
        response = await axios.post(
          `${path}/sendUserDetailsalterNate`,
          { type, email, userId },
          config
        );
      }
      //console.log("REDUCER: " + response.data.userAlternate,response);
      dispatch({
        type: "USER_DETAILS_EMAIL",
        payload: response.data.userAlternate,
      });
      dispatch({ type: "SET_TYPE", payload: type });
      dispatch({ type: CLOSE_LOADER });
    } catch (error) {throw new Error(error)}
  };
};

// verifying the OTP
export const verifyOtp = (otp, oid, type, email) => {
  return async (dispatch) => {
    const config = {
      host: `${path}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `${path}`,
      },
    };
    dispatch({ type: SET_LOADER });
    try {
      const { data } =
        type === "email"
          ? await axios.post(
              `${path}/verifyaccount`,
              { otp, oid, type, email },
              config
            )
          : await axios.post(
              `${path}/verifyOtp`,
              { otp, phone: oid, type },
              config
            );
      if (!data.isValid) {
        const arr = [];
        arr.push({ msg: data.msg });
        dispatch({ type: REGISTER_ERRORS, payload: arr });
        return false;
      } else {
        dispatch({ type: "OTP_VERIFIED", payload: true });
      }
      dispatch({ type: CLOSE_LOADER });
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };
};

// verifying the OTP
export const verifyPasswordOtp = (otp, oid, type) => {
  return async (dispatch) => {
    const config = {
      host: `${path}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `${path}`,
      },
    };
    dispatch({ type: SET_LOADER });
    try {
      const { data } =
        type === "email"
          ? await axios.post(
              `${path}/verifyPasswordOtp`,
              { otp, oid, type },
              config
            )
          : await axios.post(
              `${path}/verifyPasswordOtp`,
              { otp, phone: oid, type },
              config
            );
      if (!data.isValid) {
        const arr = [];
        arr.push({ msg: data.msg });
        dispatch({ type: REGISTER_ERRORS, payload: arr });
      } else {
        dispatch({ type: "OTP_VERIFIED", payload: true });
      }
      dispatch({ type: CLOSE_LOADER });
    } catch (error) {throw new Error(error)}
  };
};
// export const acceptFriendRequest = (userId) => {
//   return async (dispatch) => {};
// };
export const follow = (userId) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${path}/searchingUserById`, {
        friendId: userId,
      });
      //console.log(response);
      dispatch({
        type: SET_FOLLOW,
        following: response.data.searchedUser.following,
        followers: response.data.searchedUser.followers,
      });
      // dispatch({type:SET_FOLLOW, payload: response.data.searchedUser.following});
    } catch (error) {throw new Error(error)}
  };
};
// registering the User
export const postRegister = (
  firstName,
  lastName,
  gender,
  birthdate,
  password,
  username,
  email,
  type
) => {
  return async (dispatch) => {
    const config = {
      host: `${path}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `${path}`,
      },
    };
    dispatch({ type: SET_LOADER });
    try {
      let response;
      if (type === "email") {
        response = await axios.post(
          `${path}/register`,
          {
            username,
            firstName,
            lastName,
            gender,
            birthdate,
            password,
            email,
            type,
          },
          config
        );
      } else {
        response = await axios.post(
          `${path}/registerPhone`,
          {
            username,
            firstName,
            lastName,
            gender,
            birthdate,
            password,
            phone: email,
            type,
          },
          config
        );
      }
      //console.log("res after register : ", response);
      if (Object.keys(response.data).length === 1) {
        dispatch({ type: REGISTER_ERRORS, payload: response.data.errors });
      } else {
        localStorage.setItem("myToken", response.data.token);
        // if(cookie){
        // if (cookie.Theme === "dark") {
        // } else {
          
        // }}
        // const cookie = localStorage.getItem("Theme");
        // const [cookie, setCookie] = useCookies(["user"]);
        // let theme = false;
        // if(cookie.Theme === "dark"){
        //   theme = true;
        //   console.log('dark')
        // } 

        let theme = getCookie("Theme");
        console.log(theme)

        if(theme && theme ==='dark'){
          
          response.data.user.theme = "dark";
      
        }

        dispatch({
          type: SET_USER,
          token: response.data.token,
          user: response.data.user,
        });

        if(theme && theme==='dark'){
          dispatch({ type: "SET_THEME", payload: "dark" });
          dispatch(UpdateThemeAction("dark"));
        }
      }
      dispatch({ type: CLOSE_LOADER });
    } catch (error) {
      console.log(error)
      dispatch({ type: CLOSE_LOADER });
    }
  };
};
// user Login
export const postLogin = (email, password) => {
  // //console.log(email,password);
  return async (dispatch) => {
    const config = {
      host: `${path}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `${path}`,
      },
    };
    try {
      dispatch({ type: SET_LOADER });
      const response = await axios.post(
        `${path}/login`,
        { email, password },
        config
      );
      console.log("Login --->", response.data);
      if (Object.keys(response.data).length <= 2) {
        dispatch({ type: LOGIN_ERRORS, payload: response.data });
      } else {
        dispatch({ type: "SET_HEADER" });
        localStorage.setItem("myToken", response.data.token);
        const d = new Date();
        localStorage.setItem("loginTime", d.getMinutes());
        dispatch({
          type: SET_USER,
          token: response.data.token,
          user: response.data.user,
        });
      }
      dispatch({ type: "SET_HEADER" });
      dispatch({ type: CLOSE_LOADER });
    } catch (error) {
      console.log(error)
      dispatch({ type: CLOSE_LOADER });
    }
  };
};
export const setSkills = (skills, unprofessionalSkills, id) => {
  //console.log("authmeth " + skills, id, unprofessionalSkills);
  return async (dispatch) => {
    let skillsArr = [];
    skills.forEach((skill) => {
      skillsArr.push(skill.name);
    });
    let UnPrskillsArr = [];
    unprofessionalSkills.forEach((skill) => {
      UnPrskillsArr.push(skill.name);
    });
    const config = {
      host: `${path}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `${path}`,
      },
    };
    try {
      dispatch({ type: SET_LOADER });
      const response = await axios.post(
        `${path}/setSkills`,
        { id, skillsArr, UnPrskillsArr },
        config
      );
      //console.log("auth skil: " + response.data.user.skills);
      // dispatch({type:"SET_HEADER"})
      dispatch({
        type: SET_USER,
        user: response.data.user,
        token: response.data.token,
      });
      localStorage.setItem("myToken", response.data.token);
      dispatch({ type: CLOSE_LOADER });
      // dispatch({ type: "UNSET_NEW_SKILLS" });
      // dispatch({ type: "UNSET_TEMP_N_SKILLS" });
    } catch (error) {
      //console.log("error in skill set:", error);
      dispatch({ type: LOGIN_ERRORS, payload: error });
      dispatch({ type: CLOSE_LOADER });
    }
  };
};

export const blockaUser = (currentChatId, userId) => {
  return async (dispatch, getState) => {
    const {
      AuthReducer: { token },
    } = getState();
    const config = {
      host: `${path}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `${path}`,
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      console.log("api before", currentChatId, userId);
      dispatch({ type: SET_LOADER });
      const response = await axios.post(
        `${path}/chat/block/${currentChatId}`,
        {
          userId: userId,
        },
        config
      );
      console.log(response.data);
      localStorage.setItem("myToken", response.data.token);
      dispatch({
        type: SET_USER,
        user: response.data.userLoggedin,
        token: response.data.token,
      });
      dispatch({ type: SET_MESSAGE, payload: response.data.msg });
      dispatch({ type: CLOSE_LOADER });
    } catch (error) {
      console.log(error);
      dispatch({ type: CLOSE_LOADER });
    }
  };
};

export const UnblockaUser = (currentChatId, userId) => {
  return async (dispatch, getState) => {
    const {
      AuthReducer: { token },
    } = getState();
    const config = {
      host: `${path}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `${path}`,
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      console.log("api before", currentChatId, userId);
      dispatch({ type: SET_LOADER });
      const response = await axios.post(
        `${path}/chat/unblock/${currentChatId}`,
        {
          userId: userId,
        },
        config
      );
      console.log(response.data);
      dispatch({
        type: SET_USER,
        user: response.data.userLoggedin,
        token: response.data.token,
      });
      localStorage.setItem("myToken", response.data.token);
      dispatch({ type: SET_MESSAGE, payload: response.data.msg });
      // localStorage.setItem("myToken", response.data.token);
      dispatch({ type: CLOSE_LOADER });
    } catch (error) {
      console.log(error);
      dispatch({ type: CLOSE_LOADER });
    }
  };
};

export const AfterblockaUser = (userId) => {
  return async (dispatch, getState) => {
    const {
      AuthReducer: { token },
    } = getState();
    const config = {
      host: `${path}`,
      headers: {
        "Access-Control-Allow-Origin": `${path}`,
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      dispatch({ type: SET_LOADER });
      const response = await axios.post(
        `${path}/getUserDataAfterBlock/${userId}`,
        {
          userId: userId,
        },
        config
      );
      localStorage.setItem("myToken", response.data.token);
      dispatch({
        type: SET_USER,
        user: response.data.userLoggedin,
        token: response.data.token,
      });
      dispatch({ type: SET_MESSAGE, payload: response.data.msg });
      dispatch({ type: CLOSE_LOADER });
    } catch (error) {
      console.log(error);
      dispatch({ type: CLOSE_LOADER });
    }
  };
};
export const inBlockingaUser = (userId, userId2) => {
  return async (dispatch, getState) => {
    const {
      AuthReducer: { token },
    } = getState();
    const config = {
      host: `${path}`,
      headers: {
        "Access-Control-Allow-Origin": `${path}`,
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      dispatch({ type: SET_LOADER });
      const response = await axios.post(
        `${path}/getUserDatainblocking/${userId}`,
        {
          userId: userId,
          userId2: userId2,
        },
        config
      );
      console.log("respiosne", response.data.user);
      localStorage.setItem("myToken", response.data.token);
      dispatch({
        type: SET_USER,
        user: response.data.user,
        token: response.data.token,
      });
      dispatch({ type: SET_MESSAGE, payload: response.data.msg });
      dispatch({ type: CLOSE_LOADER });
    } catch (error) {
      console.log(error);
      dispatch({ type: CLOSE_LOADER });
    }
  };
};

export const inunBlockingaUser = (userId, userId2) => {
  return async (dispatch, getState) => {
    const {
      AuthReducer: { token },
    } = getState();
    const config = {
      host: `${path}`,
      headers: {
        "Access-Control-Allow-Origin": `${path}`,
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      dispatch({ type: SET_LOADER });
      const response = await axios.post(
        `${path}/getUserDatainunblocking/${userId}`,
        {
          userId: userId,
          userId2: userId2,
        },
        config
      );
      console.log("respiosne", response.data.user);
      localStorage.setItem("myToken", response.data.token);
      dispatch({
        type: SET_USER,
        user: response.data.user,
        token: response.data.token,
      });
      dispatch({ type: SET_MESSAGE, payload: response.data.msg });
      dispatch({ type: CLOSE_LOADER });
    } catch (error) {
      console.log(error);
      dispatch({ type: CLOSE_LOADER });
    }
  };
};

export const AfterUnblockaUser = (userId) => {
  return async (dispatch, getState) => {
    const {
      AuthReducer: { token },
    } = getState();
    const config = {
      host: `${path}`,
      headers: {
        "Access-Control-Allow-Origin": `${path}`,
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      dispatch({ type: SET_LOADER });
      const response = await axios.post(
        `${path}/getUserDataAfterunBlock/${userId}`,
        {
          userId: userId,
        },
        config
      );
      console.log(";;;;", response);
      localStorage.setItem("myToken", response.data.token);
      dispatch({
        type: SET_USER,
        user: response.data.userLoggedin,
        token: response.data.token,
      });
      dispatch({ type: SET_MESSAGE, payload: response.data.msg });
      dispatch({ type: CLOSE_LOADER });
    } catch (error) {
      console.log(error);
      dispatch({ type: CLOSE_LOADER });
    }
  };
};

export const friendNew = (userId) => {
  return async (dispatch, getState) => {
    const {
      AuthReducer: { token },
    } = getState();
    const config = {
      host: `${path}`,
      headers: {
        "Access-Control-Allow-Origin": `${path}`,
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      dispatch({ type: SET_LOADER });
      const response = await axios.post(
        `${path}/getUserDataAfterunBlock/${userId}`,
        {
          userId: userId,
        },
        config
      );
      console.log(";;;;", response);
      localStorage.setItem("myToken", response.data.token);
      dispatch({
        type: SET_USER,
        user: response.data.userLoggedin,
        token: response.data.token,
      });
      dispatch({ type: SET_MESSAGE, payload: response.data.msg });
      dispatch({ type: CLOSE_LOADER });
    } catch (error) {
      console.log(error);
      dispatch({ type: CLOSE_LOADER });
    }
  };
};
// Edit Skills  both professional and unprofessional
export const editSkills = (skills, id, type) => {
  return async (dispatch, getState) => {
    const {
      AuthReducer: { token },
    } = getState();
    const config = {
      host: `${path}`,
      headers: {
        "Access-Control-Allow-Origin": `${path}`,
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      dispatch({ type: SET_LOADER });
      const response = await axios.post(
        `${path}/editSkills`,
        { id, skills, type },
        config
      );
      // //console.log(response);
      dispatch({
        type: UPDATE_ACCOUNT,
        payload: response.data.user,
      });
      dispatch({ type: SET_MESSAGE, payload: response.data.msg });
      localStorage.setItem("myToken", response.data.token);
      dispatch({ type: CLOSE_LOADER });
    } catch (error) {
      //console.log(error);
      dispatch({ type: LOGIN_ERRORS, payload: error.response.data.errors });
      dispatch({ type: CLOSE_LOADER });
    }
  };
};

export const reportPost = (state, id) => {
  return async (dispatch, getState) => {
    const {
      AuthReducer: { user, token },
    } = getState();
    const config = {
      host: `${path}`,
      headers: {
        "Access-Control-Allow-Origin": `${path}`,
        Authorization: `Bearer ${token}`,
      },
    };
    dispatch({ type: SET_LOADER });
    try {
      const response = await axios.post(
        `${path}/reportPost`,
        { user, state, id },
        config
      );
      if (response.data.msg === "Already reported")
        {alert("You already reported this post");}
      else {dispatch({ type: REPORTED_POSTS, payload: id });}
      dispatch({ type: REDIRECT_TRUE });
      dispatch({ type: CLOSE_LOADER });
    } catch (error) {
      dispatch({ type: CLOSE_LOADER });
      console.log(error);
    }
  };
};
export const getSkillUsers = (skill) => {
  return async (dispatch, getState) => {
    const {
      AuthReducer: {  token },
    } = getState();
    const config = {
      host: `${path}`,
      headers: {
        "Access-Control-Allow-Origin": `${path}`,
        Authorization: `Bearer ${token}`,
      },
    };
    dispatch({ type: SET_LOADER });
    try {
       await axios.get(`${path}/skillUsers/${skill}`, config);
      // //console.log(response);
    } catch (error) {throw new Error(error)}
  };
};
export const ForgetPassword = (type, email) => {
  //console.log(type);
  return async (dispatch) => {
    const config = {
      host: `${path}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `${path}`,
      },
    };
    // //console.log("FP: "+type+" "+email);
    dispatch({ type: SET_LOADER });
    try {
      let response;
      if (type === "email") {
        response = await axios.post(
          `${path}/sendForgetPassword`,
          { type, email },
          config
        );
        //console.log("Response: ", response);
      } else {
        response = await axios.post(
          `${path}/sendForgetPassword`,
          { type, phone: email },
          config
        );
      }

      //console.log("Res:", response);
      if (response.data.otp !== "User Not found with this Email.") {
        dispatch({ type: "OTP_RECIEVED", payload: response.data.otp });
        dispatch({ type: "SET_TYPE", payload: type });
        dispatch({ type: "SET_OTPHASH", payload: response.data.oid });
      }
      dispatch({ type: CLOSE_LOADER });
    } catch (error) {throw new Error(error)}
  };
};
export const createNewPassword = (type, email, password) => {
  console.log("email", email, password);
  return async (dispatch) => {
    const config = {
      host: `${path}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `${path}`,
      },
    };
    dispatch({ type: SET_LOADER });
    try {
      let response;
      if (type === "email") {
        response = await axios.post(
          `${path}/createNewPassword`,
          { type, email, password },
          config
        );
      } else {
        response = await axios.post(
          `${path}/createNewPassword`,
          { type, phone: email, password },
          config
        );
      }
      dispatch({ type: "PASSWORD_CHANGED", payload: response.data.changed });
      dispatch({ type: "SET_EMAIL", payload: "" });
      dispatch({ type: CLOSE_LOADER });
    } catch (error) {throw new Error(error)}
  };
};

export const updateEmail = (details) => async (dispatch) => {
  try {
    //console.log("Auth method: " + details);
    const response = await axios.post(`${path}/changeEmail`, details);
    if (response.data.errors.length === 0) {
      dispatch({ type: UPDATE_EMAIL, payload: response.data.user });
      return "success";
    } else {
      return response.data.errors[0].msg;
    }
  } catch (error) {throw new Error(error)}
};

// export const updateAlternateEmail =(data)=>async(dispatch)=>{
//     try{
//         //console.log(data);
//         const response = await axios.post(`${path}/changeAlternateEmail`,data);
//         //console.log("auth response: "+response.data);
//         //console.log("action;: "+response.data.alternateEmail);
//         dispatch({type:UPDATE_ALTERNATE_EMAIL, payload: response.data});

//     }catch(error){
//         //console.log(error);
//     }
// }

export const updateAccount = (updatedDetails) => {
  return async (dispatch, getState) => {
    // //console.log("Action: "+updatedDetails.firstName);
    const {
      AuthReducer: { token },
    } = getState();
    const config = {
      host: `${path}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `${path}`,
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${path}/updateAccount/${updatedDetails._id}`,
        { updatedDetails, flag: true },
        config
      );

      console.log(response);
      //console.log("Reducer account: " + response);
      dispatch({ type: UPDATE_ACCOUNT, payload: response.data });
    } catch (error) {
      console.log(error);
    }
  };
};

export const updateSocialMedia = (urlState, id) => async (dispatch) => {
 
  try {
    const response = await axios.post(
      `${path}/changeSocialMediaLinks/${id}`,
      urlState
    );
    //console.log("media action: " + response.data.facebookUrl);
    dispatch({ type: "CHANGE_SOCIAL_MEDIA_URL", payload: response.data });
  } catch (error) {throw new Error(error)};
};

export const addNewSkill = (newSkillArr) => async () => {
  try {
    console.log("newskil arr in redux: ", newSkillArr);
   
    // //console.log("token in add new skill")
    const response = await axios.post(
      `${path}/addNewSkillWithUser`,
      newSkillArr
    );
    console.log("add skill resp: " + response.data);
    // dispatch({type:"CHANGE_SOCIAL_MEDIA_URL" , payload:response.data});
  } catch (error) {throw new Error(error)}
};

export const clearSuggestedSkill = (type) => async (dispatch, getState) => {
  try {
    const {
      AuthReducer: { token, user },
    } = getState();
    // //console.log("token in add new skill")
    const config = {
      host: `${path}`,
      headers: {
        "Access-Control-Allow-Origin": `${path}`,
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.post(
      `${path}/clearSuggestedTypeSkills`,
      {
        userId: user._id,
        type,
      },
      config
    );
    console.log("cleared skill " + response);
    // dispatch({type:"CHANGE_SOCIAL_MEDIA_URL" , payload:response.data});
  } catch (error) {
    console.log(error);
  }
};

// export const updateEmail = (details) => async(dispatch)=>{
//     try{
//         const response = await axios.post(`${path}/changeEmail`,details);
//         // dispatch({type:UPDATE_EMAIL, payload: response.data});
//         //console.log(response.data);
//     }catch(error){
//         //console.log(error);
//     }
// }
