import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function formatDate(dateString) {
  // const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  const dateParts = dateString?.split("-");
  const year = dateParts[0].slice(2);
  const month = months[parseInt(dateParts[1]) - 1];
  const day = parseInt(dateParts[2]?.split(" ")[0]);
  const time = dateParts[2]?.split(" ")[2];
  const hour = parseInt(time?.split(":")[0]);
  const minute = parseInt(time?.split(":")[1]);
  const period = hour >= 12 ? "pm" : "am";
  const hour12 = hour > 12 ? hour - 12 : hour === 0 ? 12 : hour;

  return `${day} ${month} ${year}, ${hour12}:${
    minute < 10 ? "0" + minute : minute
  } ${period}`;
}

const SingleComponent = ({
  data,
  id,
  // onClick,
  adminAccess,
}) => {
  const [regBefore, setRegBefore] = useState();

  useEffect(() => {
    setRegBefore(data[3]?.fields[0]?.response);
  }, []);

  return (
    <div className="inner mb-2 shadow-md rounded-lg text-[#329999]  bg-white hover:bg-gray-200 hover:border-white border border-[#329999] flex justify-between px-3 ">
      <div className="max-w-sm  ">
        <div className="flex items-start ">
          <div className="flex items-center">
            <div className=" flex  space-x-3 justify-between px-3  ">
              <Link to={`/submissionList/${id}/${adminAccess}`}>
                <h3 className="text-lg font-semibold ">
                  {data[2].fields[0].response}
                </h3>
              </Link>
            </div>
          </div>

          {/* Add more icons or elements here if needed */}
        </div>
      </div>
      {regBefore?.length && (
        <h3 className="text-black"> {formatDate(regBefore)}</h3>
      )}
    </div>
  );
};

export default SingleComponent;
