import React from "react";
import { useState, useEffect } from "react";
import { path } from "../../path";
import axios from "axios";
import "./AllCompetitions.css"
import { useHistory } from "react-router-dom"

// import Avatar_similar_user from "../SimillarUsers/Avatar_similar_user.js";
import "./skills.css";
import {  Card } from "@mui/material";
import CompCard from "../competition/CompCard";
const UserComps = () => {
const history = useHistory()



  const [contests, setContests] = useState([]);
 

  useEffect(() => {
    const getContests = async () => {
      try {
        // const config = {
        //   host: `${path}`,
        //   headers: {
        //     "Access-Control-Allow-Origin": `${path}`,
        //     Authorization: `Bearer ${token}`,
        //   },
        //   // token,
        // };
        const res = await axios.get(`${path}/api/response/getAllresponse?limit=5`, );
        console.log(res?.data.data, "contest");
        // const x = res.data.filter((r) => r.status == "upcoming")

        setContests(res?.data.data);

         // Setting the first contest as default


      } catch (err) {
        console.log(err);
      }
    };
    getContests();
  }, []);
  console.log(contests)
 

  return (
    <>
    {contests && 
    <div
      style={{
        overflow: "scroll",
        "@media (min-width: 767px)": {
          maxHeight: "71vh", // Adjust this accordingly for smaller devices
        },
      }}
      className={" w-[100%] h-max-[65vh] "}>
    {contests.length>0 && contests?.map((contest) => (
            <Card key={contest?._id} className="mb-4">
              <CompCard contest={contest} ></CompCard>
            </Card>
          ))}

    </div>
        }
        {contests.length>0 && <button onClick={()=>history.push(`/competition`)}>Show More</button>}
        </>
  );
};

export default React.memo(UserComps);
