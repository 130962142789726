import React, { useEffect, useState } from "react";
import MobileFooter from "../footer/MobileFooter";

import SingleCompetition from "./SingleCompetition";
import Subcompetition from "./Subcompetition";
import "./competition.css";
import { useDispatch, useSelector } from "react-redux";
import { path } from "../../path";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom";
import axios from "axios";
import LogoutModal from "../Modals/LogoutModal";
import { useContext } from "react";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { WebSocketContext } from "../../WebSocket";
import EditContest from "./EditContest";
import EnterPasswordModal from "../Modals/EnterPasswordModal";
function formatDate(dateString) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  const dateParts = dateString?.split("-");
  const year = dateParts[0].slice(2);
  const month = months[parseInt(dateParts[1]) - 1];
  const day = parseInt(dateParts[2]?.split(" ")[0]);
  const time = dateParts[2]?.split(" ")[2];
  const hour = parseInt(time?.split(":")[0]);
  const minute = parseInt(time?.split(":")[1]);
  const period = hour >= 12 ? "pm" : "am";
  const hour12 = hour > 12 ? hour - 12 : hour === 0 ? 12 : hour;

  return `${day} ${month} ${year}, ${hour12}:${
    minute < 10 ? "0" + minute : minute
  } ${period}`;
}

const Competition = () => {
  const history = useHistory();

  const { user, token } = useSelector((state) => state.AuthReducer);
  const [contestData, setContestData] = useState([]);
  const [selectedCompetition, setSelectedCompetition] = useState();
  const [isRightSectionOpen, setIsRightSectionOpen] = useState(true);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  // const [ongoingContests, setOngoingContests] = useState([]);
  const [upcommingContests, setUpcommingContests] = useState([]);
  const [currentDate, _] = useState(new Date());
  const [modal, setModal] = useState(false);
  const [accessPassword, setAccessPassword] = useState("");
  // const [errMsg, setErrMsg] = useState(null);
  const [selectItem, setSelectItem] = useState(0);

  const dispatch = useDispatch();

  const socket = useContext(WebSocketContext);
  function logoutSocketEvent() {
    socket.emit("disconnectt", user._id);
  }

  const handleRightSectionClose = () => {
    setIsRightSectionOpen(false);
  };
  const [
    contestCreateScreen,
    //  setContestCreateScreen
  ] = useState(true);
  const [editable, setEditable] = useState(false);
  const [editableProp, setEditableProp] = useState({});

  const [endedContest, setEndedContests] = useState([]);
  useEffect(() => {
    dispatch({ type: "UNSET_HEADER" });
  }, []);

  async function getContest() {
    const config = {
      host: `${path}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `${path}`,
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.get(`${path}/contest`, config);
      setContestData(response.data);
    } catch (err) {
      console.log(err.message);
    }
  }

  useEffect(() => {
    getContest();

    // setUpcommingContests(
    //   contestData?.filter(
    //     (contest) =>
    //       (contest?.status === "upcoming" || contest?.status === "upcomming" || contest.status === "") && (new Date(contest?.registrationStartDate)>new Date())
    //   )
    // );
  }, []);
  useEffect(() => {
    setUpcommingContests(
      contestData?.filter(
        (contest) =>
          new Date(
            formatDate(contest.responses.sections[3]?.fields[0]?.response)
          ) > currentDate
      )
    );
    setEndedContests(
      contestData?.filter(
        (contest) =>
          new Date(
            formatDate(contest.responses.sections[3]?.fields[0]?.response)
          ) < currentDate
      )
    );
  }, [contestData]);

  useEffect(() => {
    getContest();
  }, [editable]);

  const handleAdminAccess = async (pass) => {
    try {
      const response = await axios.post(`${path}/checkAdminAccess`, {
        email: user.email,
        password: pass,
      });
      if (response.data.status === "success") {
        setModal(false);
        history.push(`/editcontest/${selectItem}`);
      }
      if (response.data.status === "failed") {
        // setErrMsg(response.data.message);
        alert("Wrong password, enter again");
        setAccessPassword("");
      }
    } catch (error) {
      console.log("ERROR ", error);
    }
  };
  const handleModal = (id, hasAccess) => {
    if (hasAccess === false) {
      history.push(`/editcontest/${id}`);
    } else {
      setModal(true);
    }
    setAccessPassword("");
    setSelectItem(id);
  };

  return (
    <>
      <div className="relative bg-white ">
        {modal && (
          <EnterPasswordModal
            handleAdminAccess={handleAdminAccess}
            setAccessPassword={setAccessPassword}
            setModal={setModal}
            accessPassword={accessPassword}
            message={"edit the competition"}
          />
        )}
        <div className={`fixed left-0 z-30`}>
          <LogoutModal
            showLogoutModal={showLogoutModal}
            setShowLogoutModal={setShowLogoutModal}
            logoutSocketEvent={logoutSocketEvent}
          />
        </div>

        <div className="main-title h-full">
          <a onClick={() => setShowLogoutModal(true)}>
            <li className="flex  py-[1%] items-center cursor-pointer">
              <LogoutOutlinedIcon
                fontSize="medium"
                className="text-slate-500 mx-[2%] mr-[5%] mt-[2%]"
              />{" "}
              <span className="mt-[2%] text-[#767676] ml-[3px]">Logout </span>{" "}
            </li>
          </a>
        </div>
        <div className="bg-white  hidden md:flex flex-1  justify-around items-center">
          <div className="hidden font-bold text-3xl text-[#008080] ml-[3px] pt-3 h-44 w-64 border border-transparent rounded-lg xl:flex items-center justify-center capitalize self-start mt-6 shadow-lg">
            <p className="text-center">Admin Section</p>
          </div>
          <div className="flex justify-center   text-primary bg-white  p-4 border rounded-lg  overflow-hidden h-[35rem]">
            <div className="left-section  w-[100vw] md:w-[42vw] lg:w-[35vw] xl:w-[25vw]">
              <div className="flex flex-col sm:flex-row w-full items-center justify-center mb-2">
                {/* <button
                onClick={() => {
                  setContestCreateScreen(true);
                  setSelectedCompetition();
                }}
                className="bg-teal-500 text-white px-3 py-1 rounded-md w-full hover:bg-teal-600">
                Create Contest
              </button> */}
              </div>
              {/* <h1 className="text-center text-primary font-bold">
              Competitions
            </h1> */}
              <div className="h-[30rem]  overflow-y-auto absolute pb-12">
                {/* <h1 className="text-xl font-semibold">Upcoming</h1>
              {upcommingContests.map((contest, index) => (
                <div className="cursor-pointer">
                  <SingleCompetition
                    key={index}
                    data={contest}
                    onClick={handleCompetitionClick}
                  />
                </div>
              ))} */}
                <div className="mt-1 text-lg text-white flex border border-transparent rounded-lg mb-2 items-center justify-center font-semibold bg-[#008080] h-12 w-[20rem] lg:w-[24rem]">
                  <p>Ongoing competitions ({upcommingContests.length})</p>
                </div>
                {upcommingContests.map((contest) => (
                  <div className="cursor-pointer " key={contest?._id}>
                    <Link
                      to={`/submissionList/${contest._id}/${contest.adminAccess}`}
                      className="no-underline">
                      <SingleCompetition
                        data={contest.responses.sections}
                        id={contest._id}
                        adminAccess={contest.adminAccess}
                      />
                    </Link>
                    <div className="flex">
                      {" "}
                      <h2
                        // to={`/editcontest/${contest._id}`}
                        onClick={() => {
                          handleModal(contest._id, contest.adminAccess);
                        }}
                        className="no-underline hover:text-red-800">
                        Edit
                      </h2>
                      <Link
                        to={`/rewards/${contest._id}`}
                        className="no-underline mx-4">
                        Payout
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {!contestCreateScreen && (
              <div className="w-full">
                {!editable ? (
                  <div className="overflow-y-auto hidden text-[#1c4980] w-full text-center h-full m-auto text-[1.5rem] md:flex justify-center items-start">
                    <Subcompetition
                      setEditable={setEditable}
                      setEditableProp={setEditableProp}
                      data={selectedCompetition}
                    />
                  </div>
                ) : (
                  <div className=" w-full overflow-y-auto h-full">
                    <div
                      className="bg-teal-500 cursor-pointer px-2 py-1 rounded-md text-white"
                      onClick={() => {
                        setEditableProp({});
                        setEditable(false);
                      }}>
                      Cancel
                    </div>
                    <EditContest
                      setSelectedCompetition={setSelectedCompetition}
                      setEditable={setEditable}
                      props={editableProp}
                    />
                  </div>
                )}
              </div>
            )}
            {isRightSectionOpen &&
              (!contestCreateScreen ? (
                <div className="right-section-overlap md:hidden absolute top-0 left-0  h-full bg-white z-10">
                  <div className="flex justify-end">
                    <button
                      className="text-red-500"
                      onClick={handleRightSectionClose}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                  {!editable ? (
                    <Subcompetition
                      setEditable={setEditable}
                      setEditableProp={setEditableProp}
                      data={selectedCompetition}
                    />
                  ) : (
                    <EditContest
                      setEditable={setEditable}
                      props={editableProp}
                    />
                  )}
                </div>
              ) : (
                <div className=" justify-center w-1/2 p-3 ml-5">
                  {/* <h1 className="text-center font-bold text-3xl">
                  Admin Panel
                </h1> */}
                  <div className=" text-lg text-white flex border border-transparent rounded-lg mb-2 items-center justify-center font-semibold bg-[#008080] h-12 w-[20rem] lg:w-[24rem]">
                    <p>Ended competitions ({endedContest.length})</p>
                  </div>
                  <div className="h-[30rem]  overflow-y-auto pb-12 px-3">
                    {endedContest.map((contest) => (
                      <div className="cursor-pointer my-3" key={contest._id}>
                        <Link
                          to={`/submissionList/${contest._id}/${contest.adminAccess}`}
                          className="no-underline">
                          <SingleCompetition
                            data={contest.responses.sections}
                            id={contest._id}
                            adminAccess={contest.adminAccess}
                          />
                        </Link>
                        <div className="flex">
                          {" "}
                          <h2
                            // to={`/editcontest/${contest._id}`}
                            onClick={() => {
                              handleModal(contest._id, contest.adminAccess);
                            }}
                            className="no-underline hover:text-red-800">
                            Edit
                          </h2>
                          <Link
                            to={`/rewards/${contest._id}`}
                            className="no-underline mx-4">
                            Payout
                          </Link>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="hidden">
          <MobileFooter />
        </div>
      </div>
      <div className="sm:hidden flex flex-col items-center bg-white">
        <div className="mt-4 text-lg text-white flex border border-transparent rounded-lg mb-2 items-center justify-center font-semibold bg-[#008080] h-12 w-[20rem] lg:w-[24rem]">
          <p>Ongoing competitions ({upcommingContests.length})</p>
        </div>
        <div className="h-[16rem] mb-2  mx-2 overflow-y-auto pb-12 px-3">
          {upcommingContests.map((contest) => (
            <div className="cursor-pointer " key={contest?._id}>
              <Link
                to={`/submissionList/${contest._id}/${contest.adminAccess}`}
                className="no-underline">
                <SingleCompetition
                  data={contest.responses.sections}
                  id={contest._id}
                  adminAccess={contest.adminAccess}
                />
              </Link>
              <div className="flex">
                {" "}
                <h2
                  // to={`/editcontest/${contest._id}`}
                  onClick={() => {
                    handleModal(contest._id, contest.adminAccess);
                  }}
                  className="no-underline hover:text-red-800">
                  Edit
                </h2>
                <Link
                  to={`/rewards/${contest._id}`}
                  className="no-underline mx-4">
                  Payout
                </Link>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-3 text-lg text-white flex border border-transparent rounded-lg mb-2 items-center justify-center font-semibold bg-[#008080] h-12 w-[20rem] lg:w-[24rem]">
          <p>Ended competitions ({endedContest.length})</p>
        </div>
        <div className="h-[16rem]  mx-2 overflow-y-auto pb-12 px-3">
          {endedContest.map((contest) => (
            <div className="cursor-pointer my-3" key={contest._id}>
              <Link
                to={`/submissionList/${contest._id}/${contest.adminAccess}`}
                className="no-underline">
                <SingleCompetition
                  data={contest.responses.sections}
                  id={contest._id}
                  adminAccess={contest.adminAccess}
                />
              </Link>
              <div className="flex">
                {" "}
                <h2
                  // to={`/editcontest/${contest._id}`}
                  onClick={() => {
                    handleModal(contest._id, contest.adminAccess);
                  }}
                  className="no-underline hover:text-red-800">
                  Edit
                </h2>
                <Link
                  to={`/rewards/${contest._id}`}
                  className="no-underline mx-4">
                  Payout
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Competition;
