import React, { useContext, useEffect, useState } from 'react'
import CompetitionMobile from './competitionMobile'
import CompetetionDesktop from './CompetetionDesktop'
import { WebSocketContext } from '../../WebSocket';
import Header from '../Header/Header';

function AllComp() {

    const socket = useContext(WebSocketContext);
    const [progress, setProgress] = useState(null);
    const [processing, setprocessing] = useState(true); 

    useEffect(() => {
        socket.on('chunk_upload', (data) => {
            setProgress(data.percentage);
        });
        socket.on('on_contest_submission', (data) => {
            if (!data.processing)
                {setprocessing(false);}
        });

        return (() => {
            socket.off('chunk_upload')
            socket.off('on_contest_submission')
        })
    }, [socket])
    return (

        <>  
          <div className="App h-[100vh] bg-compBgSec">
           <Header/>
            <div className='md:hidden'>
                <CompetitionMobile progress={progress} processing={processing} />
            </div>
            <div className='hidden md:block bg-compBgSec'>
                <CompetetionDesktop progress={progress} processing={processing} />
            </div>
</div>
            
        </>

    )
}

export default AllComp