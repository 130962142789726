import React from "react";
import C from "./CompetitionUI/C"
import G from "./CompetitionUI/G"
import F from "./CompetitionUI/F"
import Winner from "./CompetitionUI/Winner";

const CompDetails2 = ({ contestData }) => {
  // const { user, token } = useSelector((state) => state.AuthReducer);
  console.log(contestData)
  return (
    <div className="mb-[15%] overflow-y-auto" >
      {contestData && contestData.responses && (
        <>

          {/* <Card> */}
          <div className="bg-compBgSec rounded-md xl:p-3 lg:py-3 flex flex-col items-center ">
            {/* <CardContent className="inline"> */}
            <div className="flex flex-col lg:p-1 xl:p-3 items-center lg:h-fit  xl:max-h-[33vh] overflow-y-scroll bg-compBgSec text-primary" style={{scrollbarWidth: "none"}}>
              {/* General info like dates */}
              <C fields={contestData.responses.sections[3]?.fields}></C>
            </div>
            {/* </CardContent> */}
          </div>
          {/* </Card> */}
          <div className="mt-2 rounded-md">
            <div className="bg-compBgSec">

              <div>
                {/* Judges */}
                <div className="h-fit overflow-y-scroll bg-compBgSec" style={{ scrollbarWidth: "none" }}>

                  <G fields={contestData.responses.sections[7]?.fields}></G>
                </div>
              </div>
            </div>
          </div>

          {/* h-[30vh] overflow-y-scroll  */}
          {contestData.result && contestData.result.length > 0 ? (
            <>
              <div className="mt-2 rounded-md">
                <div className="bg-compBgSec rounded-md">
                  <div>
                    <div className="mlg:h-[33vh] lg:max-h-fit  overflow-y-scroll bg-compBgSec" style={{ scrollbarWidth: "none" }}>
                      <Winner contestId={contestData?._id}></Winner>
                    </div>

                  </div>
                </div>

              </div>
            </>

          ) : (
            <div className="mt-2 rounded-md">
              <div className="bg-compBgSec rounded-md">
                <div>
                  <div className="mlg:h-[30vh] lg:max-h-fit  overflow-y-scroll bg-compBgSec" style={{ scrollbarWidth: "none" }}>
                    <F fields={contestData.responses.sections[6]?.fields}></F>
                  </div>
                </div>
              </div>

            </div>

          )}



        </>
      )}
    </div>

  )
}
export default CompDetails2
